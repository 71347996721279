import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
const _types = [
	{
		key: "DefectiveAirbag",
		expand: false
	},
	{
		key: "dValue",
		expand: false
	},
	{
		key: "DUILawyer",
		expand: false
	},
	{
		key: "__CALawyer",
		expand: false
	},
	{
		key: "Chiropractor",
		expand: false
	}
	// {
	// 	title: "Auto Insurance - Non Standard",
	// 	key: "NSInsurance"
	// }
];

export default function LeadTypesChooser({ leadTypes, onChange }) {
	const [types, setTypes] = useState(_types);
	const [notInterestedExpand, setNotInterestedExpand] = useState(false);
	const { t } = useTranslation();

	const toggle = (key) => {
		if (key === "notInterested" && !leadTypes[key]) {
			leadTypes = { notInterested: true };
		} else if (key === "notInterested" && leadTypes[key]) {
			leadTypes = {};
		} else if (leadTypes[key]) {
			delete leadTypes[key];
		} else {
			leadTypes[key] = "AM";
			delete leadTypes.notInterested;
		}
		console.log(leadTypes);
		onChange({ ...leadTypes });
	};

	const timeSwitch = (time, key) => {
		leadTypes[key] = time;
		delete leadTypes.notInterested;
		onChange({ ...leadTypes });
	};

	return (
		<div
			style={{ maxWidth: 700, margin: "auto" }}
			className="lead-types-screen"
		>
			<h2 style={{ color: "var(--orange)" }}>
				{t("GET_HELP_IN_YOUR_RECOVERY_PROCESS")}
			</h2>
			<br />
			<p>
				{t(
					"MYACCIDENT_ORG_IS_A_FREE_SERVICE_THAT_IS_PAID_FOR_BY_LOCAL_SPONSORS"
				)}
			</p>
			<br />
			<h3>{t("HOW_IT_WORKS_")}</h3>
			<br />
			<div style={{ display: "flex", alignItems: "center" }}>
				<div className="icon">
					<img src="/assets/checked.svg" />
				</div>
				<div>
					<Trans i18nKey={"YOU_SELECT_ONE_OR_MORE"} />
				</div>
			</div>
			<div style={{ height: 30 }} />
			<div style={{ display: "flex", alignItems: "center" }}>
				<img src="/assets/callme.png" className="call-me" />
				<div>
					<Trans i18nKey={"WE_CALL_YOU_AND_CONNECT_YOU"} />
				</div>
			</div>
			<div style={{ height: 50 }} className="hide-mobile" />
			<div style={{ height: 20 }} className="hide-desktop" />
			<table className="lead-types-table">
				<thead>
					<tr>
						<td colSpan="2">
							<h2>{t("GET_A_LOCAL_QUOTE_FOR_")}</h2>
						</td>
						<td />
						<td>{t("YOUR_CALL_PREFERENCE")}</td>
						{/* <td></td> */}
					</tr>
				</thead>
				<tbody>
					{types.map((ty) => (
						<tr key={ty.key}>
							<td>
								<a
									onClick={() => {
										ty.expand = !ty.expand;
										setTypes([...types]);
									}}
									className={ty.expand ? "expand" : ""}
									data-testid={"expand-" + ty.key}
								>
									<img src="/assets/play-button.png" />
								</a>
							</td>
							<td
								onClick={() => toggle(ty.key)}
								data-testid={"toggle-" + ty.key}
							>
								{t(`LEAD_TYPES.${ty.key}.title`)}
								<span
									className={ty.expand ? "description expand" : "description"}
								>
									<Trans i18nKey={`LEAD_TYPES.${ty.key}.description`} />
								</span>
							</td>
							<td className={leadTypes[ty.key] ? "checked" : ""}>
								<div
									className="yes-no-box"
									onClick={() => toggle(ty.key)}
									data-testid={"toggle2-" + ty.key}
								>
									<img src="/assets/checked.svg" />
								</div>
							</td>
							<td>
								{leadTypes[ty.key] ? (
									<>
										<a
											className={
												leadTypes[ty.key] === "AM" ? "am-pm checked" : "am-pm"
											}
											onClick={() => timeSwitch("AM", ty.key)}
											data-testid={"time-" + ty.key}
										>
											{t("AM")}
										</a>
										<a
											className={
												leadTypes[ty.key] === "PM" ? "am-pm checked" : "am-pm"
											}
											onClick={() => timeSwitch("PM", ty.key)}
											data-testid={"time2-" + ty.key}
										>
											{t("PM")}
										</a>
									</>
								) : null}
							</td>
							{/* <td style={{ fontSize: 12 }}>
								{ty.key === "__CALawyer" && leadTypes["__CALawyer"]
									? t(
											"BY_CLICKING_THIS__I_AGREE_TO_BE_CONTACTED_BY_ONE_OR_MORE_LAWYERS_"
									  )
									: null}
							</td> */}
						</tr>
					))}
					{/* <tr>
						<td>
							<a
								onClick={() => {
									setNotInterestedExpand(!notInterestedExpand);
								}}
								className={notInterestedExpand ? "expand" : ""}
								data-testid="expand-not-interested"
							>
								<img src="/assets/play-button.png" />
							</a>
						</td>
						<td
							onClick={() => toggle("notInterested")}
							data-testid="toggle-not-interested"
						>
							<Trans i18nKey={"NO_THANKS__NOT_INTERESTED"} />
							<span
								className={
									notInterestedExpand ? "description expand" : "description"
								}
							>
								{t("I_M_NOT_INTERESTED_IN_ANY_LOCAL_SERVICES_")}
							</span>
						</td>
						<td className={leadTypes.notInterested ? "checked" : ""}>
							<div
								className="yes-no-box"
								onClick={() => toggle("notInterested")}
								data-testid="toggle2-not-interested"
							>
								<img src="/assets/checked.svg" />
							</div>
						</td>
						<td></td>
					</tr> */}
				</tbody>
			</table>
			{leadTypes.notInterested ? (
				<p style={{ margin: "10px 20px" }}>
					<small>
						<Trans i18nKey={"KEEPING_MYACCIDENT_ORG_FREE"} />{" "}
						<a href="https://myaccident.org/terms-of-use" target="_blank">
							{t("TERMS_OF_USE")}.
						</a>
					</small>
				</p>
			) : null}
		</div>
	);
}
