import React, { useState } from "react";
import clsx from "clsx";

export default function LeJeJuneQuestions({
	next,
	back,
	q1,
	q2,
	q3,
	setQ1,
	setQ2,
	setQ3
}) {
	const [hover, setHover] = useState("");

	return (
		<div>
			<div
				className={clsx(["alert-options", "leJeJune"], {
					hasSelected: q1 !== undefined && q2 !== undefined && q3 !== undefined
				})}
			>
				<div className="left">
					<div
						className={clsx(["option", { selected: q1 === undefined }])}
						onMouseOver={() => setHover("q1")}
						onMouseOut={() => setHover("")}
					>
						<div className="inner">
							<strong style={{ fontSize: 18 }}>
								Were you or a loved one stationed at Camp Lejeune between 1953
								and 1987?
							</strong>
							<div className="row yes-no">
								<div
									onClick={() => setQ1(false)}
									className={q1 === false ? "checked" : ""}
								>
									NO
									<div className="yes-no-box">
										<img src="/assets/checked.svg" />
									</div>
								</div>

								<div
									onClick={() => setQ1(true)}
									className={q1 ? "checked" : ""}
								>
									YES
									<div className="yes-no-box">
										<img src="/assets/checked.svg" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className={clsx([
							"option",
							{ selected: q2 === undefined && q1 !== undefined }
						])}
						onMouseOver={() => setHover("q2")}
						onMouseOut={() => setHover("")}
					>
						<div className="inner">
							<strong style={{ fontSize: 18 }}>
								Did You Or A Loved One Develop A Serious Illness Due To Toxic
								Drinking Water At Camp Lejeune?
							</strong>
							<div className="row yes-no">
								<div
									onClick={() => setQ2(false)}
									className={q2 === false ? "checked" : ""}
								>
									NO
									<div className="yes-no-box">
										<img src="/assets/checked.svg" />
									</div>
								</div>

								<div
									onClick={() => setQ2(true)}
									className={q2 ? "checked" : ""}
								>
									YES
									<div className="yes-no-box">
										<img src="/assets/checked.svg" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className={clsx([
							"option",
							{ selected: q3 === undefined && q2 !== undefined }
						])}
						onMouseOver={() => setHover("q3")}
						onMouseOut={() => setHover("")}
					>
						<div className="inner">
							<strong style={{ fontSize: 18 }}>
								Did you or a loved one spend at least 30 days at Camp Lejeune
								between 1953-1987?
							</strong>
							<div className="row yes-no">
								<div
									onClick={() => setQ3(false)}
									className={q3 === false ? "checked" : ""}
								>
									NO
									<div className="yes-no-box">
										<img src="/assets/checked.svg" />
									</div>
								</div>

								<div
									onClick={() => setQ3(true)}
									className={q3 ? "checked" : ""}
								>
									YES
									<div className="yes-no-box">
										<img src="/assets/checked.svg" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="right hide-mobile">
					<div
						className={hover === "q1" ? "option-image hover" : "option-image"}
						onMouseOver={() => setHover("q1")}
						onMouseOut={() => setHover("")}
					>
						<div className="inner">
							<img src="/assets/LeJeJuneQ1.png" />
							<span>i</span>
							{q1 ? (
								<div
									style={{
										marginLeft: 18,
										display: "flex",
										justifyContent: "center",
										alignItems: "center"
									}}
								>
									<img
										src="/assets/green-check.png"
										style={{ width: 20, height: 20 }}
									/>
									<div>Present between 1953 and 1987</div>
								</div>
							) : null}
						</div>
						<article>
							<strong>Who Qualifies?</strong>
							<br />• Military Personnel, Civilian Personnel, Family Members
							exposed to contaminated water In the Camp Lejeune or MCAS New
							River Between 1953 and 1987.
							<br />• Family members representing deceased relatives that were
							exposed to contaminated water
						</article>
					</div>
					<div
						className={hover === "q2" ? "option-image hover" : "option-image"}
						onMouseOver={() => setHover("q2")}
						onMouseOut={() => setHover("")}
					>
						<div className="inner">
							<img src="/assets/LeJeJuneQ2.png" />
							<span>i</span>
							{q2 ? (
								<div
									style={{
										marginLeft: 18,
										display: "flex",
										justifyContent: "center",
										alignItems: "center"
									}}
								>
									<img
										src="/assets/green-check.png"
										style={{ width: 20, height: 20 }}
									/>
									<div>
										Serious illness developed due to toxic drinking water
									</div>
								</div>
							) : null}
						</div>
						<article>
							<strong>
								Marines, military employees, and their families have developed
								serious illnesses from exposure to the contaminated water at
								Camp Lejeune. Some of the illnesses include:
							</strong>
							<br />
							Bladder cancer, Kidney cancer, Liver cancer, Leukemia, Breast
							cancer, Lung cancer, Esophageal cancer, Pancreatic cancer,
							Cervical cancer, Multiple myeloma, Non-Hodgkin’s Lymphoma, Cardiac
							defects, Aplastic anemia, ALS (Lou Gehrig’s Disease) Fatty liver
							disease (Hepatic steatosis), Parkinson’s Disease, Renal toxicity,
							Neurobehavioral effects, Scleroderma, Brain damage Chronic Joint
							Pain
						</article>
					</div>
					<div
						className={hover === "q3" ? "option-image hover" : "option-image"}
						onMouseOver={() => setHover("q3")}
						onMouseOut={() => setHover("")}
					>
						<div className="inner">
							<img src="/assets/LeJeJuneQ3.png" />
							<span>i</span>
							{q3 ? (
								<div
									style={{
										marginLeft: 18,
										display: "flex",
										justifyContent: "center",
										alignItems: "center"
									}}
								>
									<img
										src="/assets/green-check.png"
										style={{ width: 20, height: 20 }}
									/>
									<div>
										At least 30 days spent at Camp Lejeune between 1953 and 1987
									</div>
								</div>
							) : null}
						</div>
						<article>
							Veterans and their families who lived or worked at Camp Lejeune{" "}
							<strong>
								for at least 30 cumulative days between August 1st, 1953 through
								December 31st, 1987
							</strong>{" "}
							may be entitled to compensation.
						</article>
					</div>
				</div>
			</div>
			<div className="buttons leJeJune">
				{/* <button
					onClick={() => {
						back();
					}}
					className="white"
					type="button"
				>
					Back
				</button> */}
				<button
					className="green"
					onClick={() => next({ q1, q2, q3 })}
					disabled={q1 === undefined || q2 === undefined || q3 === undefined}
				>
					Calculate My Claim Now!
				</button>
			</div>
			<div style={{ height: 60 }} className="hide-mobile" />
			<div style={{ height: 10 }} className="hide-desktop" />
			<p style={{ textAlign: "center" }}>
				<img src="/assets/ssl.svg" width="150" />
			</p>
		</div>
	);
}
