import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "react-google-autocomplete";
import MapPicker from "./MapPicker";
import dayjs from "dayjs";
import Axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "react-mobile-datepicker";
import AppContext from "../context";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const monthMap = {
	1: "Jan",
	2: "Feb",
	3: "Mar",
	4: "Apr",
	5: "May",
	6: "Jun",
	7: "Jul",
	8: "Aug",
	9: "Sep",
	10: "Oct",
	11: "Nov",
	12: "Dec"
};

const dateConfig = {
	year: {
		format: "YYYY",
		caption: "Year",
		step: 1
	},
	month: {
		format: (value) => monthMap[value.getMonth() + 1],
		caption: "Mon",
		step: 1
	},
	date: {
		format: "DD",
		caption: "Day",
		step: 1
	}
};
export default function AlertTypeChooser({
	alertType,
	setAlertType,
	next,
	setAddress,
	setJustCity,
	setState,
	setZip,
	back,
	justCity
}) {
	const [hover, setHover] = useState("");
	const [mapClicked, setMapClicked] = useState(false);
	const [step, setStep] = useState(0);
	const [mobileDatePicker, setMobileDatePicker] = useState(false);
	const { vehicleMakerList } = useContext(AppContext);
	const { t } = useTranslation();

	const {
		register,
		unregister,
		handleSubmit,
		errors,
		control,
		setValue,
		getValues
	} = useForm({ mode: "onBlur" });

	useEffect(() => {
		if (alertType === "know") {
			register("city", { required: true });
			register("location", { required: true });
		} else {
			unregister("city");
			unregister("location");
		}
	}, [alertType]);

	const getActualYear = () => {
		const date = new Date();
		return date.getFullYear();
	}

	const findCity = (v) => {
		// console.log(v);
		let gotZip;
		let city;
		let sstate;
		setAddress(v.formatted_address);
		setJustCity(v.formatted_address);
		setMapClicked(true);
		if (v.address_components) {
			v.address_components.forEach((vv) => {
				if (vv.types.includes("administrative_area_level_1")) {
					// console.log("FOUND STATE", vv.short_name);
					setState(vv.short_name);
					sstate = vv.short_name;
				}
				if (vv.types.includes("postal_code")) {
					// console.log("FOUND ZIP", vv.short_name);
					setZip(vv.short_name);
					gotZip = true;
				}
				if (vv.types.includes("locality") && vv.types.includes("political")) {
					// console.log("FOUND CITY", vv.short_name);
					city = vv.short_name;
					setJustCity(city);
				}
			});
		}
		if (!gotZip && city && sstate) {
			// console.log(
			// 	"FETCH ZIP FROM API:",
			// 	v.geometry.location.lat(),
			// 	v.geometry.location.lng()
			// );
			Axios.get(
				`accident/zip?lat=${v.geometry.location.lat()}&lng=${v.geometry.location.lng()}&city=${city}&state=${sstate}`
			).then((d) => {
				if (d.data.success && d.data.area) {
					// console.log("FOUND ZIP FROM API:", d.data.area.code);
					setZip(d.data.area.code);
				}
			});
		}
	};

	return (
		<div>
			<div className={clsx(["alert-options"], { hasSelected: alertType })}>
				<div className="left">
					<div
						className={clsx([
							"option",
							{ hover: hover === "vin" },
							{ selected: alertType === "vin" }
						])}
						onMouseOver={() => setHover("vin")}
						onMouseOut={() => setHover("")}
						onClick={() => setAlertType("vin")}
					>
						<div className="inner">
							<div className="circled">
								<img src="/assets/icon-vin-2.svg" />
							</div>
							<article>
								<h2>{t("VIN_NUMBER")}</h2>
								{t("I_KNOW_THE_VIN_NUMBER_OF_A_VEHICLE_INVOLVED")}
								<button className="hide-desktop">{t("SELECT")}</button>
							</article>
							<div className="check" />
						</div>
					</div>
					<div
						className={clsx([
							"option",
							{ hover: hover === "case" },
							{ selected: alertType === "case" }
						])}
						onMouseOver={() => setHover("case")}
						onMouseOut={() => setHover("")}
						onClick={() => setAlertType("case")}
					>
						<div className="inner">
							<div className="circled">
								<img src="/assets/icon-case.svg" />
							</div>
							<article>
								<h2>{t("CASE_ID")}</h2>
								{t("I_KNOW_THE_CASE_ID_OF_THE_ACCIDENT")}
								<button className="hide-desktop">{t("SELECT")}</button>
							</article>
							<div className="check" />
						</div>
					</div>
					<div
						className={clsx([
							"option",
							{ hover: hover === "know" },
							{ selected: alertType === "know" }
						])}
						onMouseOver={() => setHover("know")}
						onMouseOut={() => setHover("")}
						onClick={() => setAlertType("know")}
					>
						<div className="inner">
							<div className="circled">
								<img src="/assets/icon-dontknow.svg" />
							</div>
							<article>
								<h2>{t("DON_T_KNOW")}</h2>
								{t(
									"I_DON_T_KNOW_EITHER_CASE_ID_OR_VIN_NUMBER_OF_A_VEHICLE_INVOLVED"
								)}
								<button className="hide-desktop">{t("SELECT")}</button>
							</article>
							<div className="check" />
						</div>
					</div>
				</div>
				{alertType === "" ? (
					<div className="right hide-mobile">
						<div
							className={
								hover === "vin" ? "option-image hover" : "option-image"
							}
							onMouseOver={() => setHover("vin")}
							onMouseOut={() => setHover("")}
							onClick={() => setAlertType("vin")}
						>
							<div className="inner">
								<img src="/assets/option-vin.svg" />
								<span>i</span>
								<article>
									{t(
										"A_VIN_NUMBER_IS_MADE_UP_OF_17_CHARACTERS__USING_NUMBERS_AND_CAPITAL_LETTERS"
									)}
								</article>
							</div>
						</div>
						<div
							className={
								hover === "case" ? "option-image hover" : "option-image"
							}
							onMouseOver={() => setHover("case")}
							onMouseOut={() => setHover("")}
							onClick={() => setAlertType("case")}
						>
							<div className="inner">
								<img src="/assets/option-caseid.svg" />
								<span>i</span>
								<article>
									{t(
										"A_CASE_ID_IS_ASSIGNED_AT_THE_ACCIDENT_AND_SHARED_WITH_THE_DRIVERS_INVOLVED_IN_THE_ACCIDENT"
									)}
								</article>
							</div>
						</div>
						<div
							className={
								hover === "know" ? "option-image hover" : "option-image"
							}
							onMouseOver={() => setHover("know")}
							onMouseOut={() => setHover("")}
							onClick={() => setAlertType("know")}
						>
							<div className="inner">
								<img src="/assets/option-dontknow.svg" />
								<span>i</span>
								<article>
									{t("USE_A_MAP_TO_LOCATE_WHERE_THE_ACCIDENT_OCCURRED")}
								</article>
							</div>
						</div>
					</div>
				) : (
					<div className="right">
						{alertType === "vin" && (
							<form onSubmit={handleSubmit(next)}>
								<h2>{t("ENTER_VIN_NUMBER")}</h2>
								<p>{t("ENTER_THE_VIN_NUMBER_WITH_ACCIDENT_DATE_AND_CITY")}.</p>
								<br />
								<br />
								<div className="input-container">
									<label>{t("VIN_NUMBER")}:</label>
									{getValues("vin") && getValues("vin").length === 17 && (
										<span className="input-ok">
											<img src="/assets/checked.svg" />
										</span>
									)}

									<input
										placeholder="Example: KM8J33A25GU262298"
										name="vin"
										type="text"
										maxLength={17}
										ref={register({
											required: true,
											maxLength: 17,
											minLength: 17
										})}
										className={errors.vin ? "err" : ""}
									/>
									{errors.vin?.type === "required" && (
										<span className="input-error">{t("VIN_IS_REQUIRED")}</span>
									)}
									{(errors.vin?.type === "maxLength" ||
										errors.vin?.type === "minLength") && (
										<span className="input-error">
											{t("VIN_SHOULD_BE_17_CHARACTERS_LONG")}
										</span>
									)}
								</div>

								<div
									className="input-container half hide-desktop"
									onClick={() => setMobileDatePicker(true)}
								>
									<label>{t("DATE_OF_ACCIDENT")}:</label>
									{getValues("date") && getValues("date").length === 10 && (
										<span className="input-ok">
											<img src="/assets/checked.svg" />
										</span>
									)}
									<div
										className={errors.date ? "err fake-input" : "fake-input"}
										style={{ pointerEvents: "none" }}
									>
										{getValues("date") || "Tap to pick"}
									</div>
									{errors.date?.type === "required" && (
										<span className="input-error">{t("DATE_IS_REQUIRED")}</span>
									)}
								</div>

								<Controller
									name="date"
									control={control}
									rules={{ required: true, validate: true }}
									defaultValue={new Date()}
									render={(props) => (
										<DatePicker
											dateConfig={dateConfig}
											value={
												props.value ? dayjs(props.value).toDate() : new Date()
											}
											isOpen={mobileDatePicker}
											onSelect={(d) => {
												props.onChange(dayjs(d).format("YYYY-MM-DD"));
												setMobileDatePicker(false);
											}}
											onCancel={() => setMobileDatePicker(false)}
											confirmText={t("CONFIRM")}
											cancelText={t("CANCEL")}
											theme="ios"
											max={new Date()}
											headerFormat={"MM/DD/YYYY"}
										/>
									)}
								/>

								<div className="input-container half hide-mobile">
									<label>{t("DATE_OF_ACCIDENT")}:</label>
									{getValues("date") && getValues("date").length === 10 && (
										<span className="input-ok">
											<img src="/assets/checked.svg" />
										</span>
									)}
									<input
										type="date"
										name="date"
										max={dayjs().format("YYYY-MM-DD")}
										ref={register({ required: true })}
										className={errors.date ? "err" : ""}
										placeholder="MM/DD/YYYY"
									/>
									{errors.date?.type === "required" && (
										<span className="input-error">{t("DATE_IS_REQUIRED")}</span>
									)}
								</div>
								<div className="input-container">
									<label>{t("CITY_OF_ACCIDENT")}:</label>
									{getValues("city") && getValues("justCity") && (
										<span className="input-ok">
											<img src="/assets/checked.svg" />
										</span>
									)}
									<Controller
										name="city"
										control={control}
										rules={{ required: true, validate: true }}
										defaultValue={""}
										render={(props) => (
											<Autocomplete
												className={errors.city || errors.justCity ? "err" : ""}
												componentRestrictions={{ country: "us" }}
												onChange={(e) => {
													props.onChange(e.target.value);
													setJustCity("");
													setAddress("");
												}}
												onPlaceSelected={(e) => {
													findCity(e);
													props.onChange(e.formatted_address);
												}}
												types={["(regions)"]}
												placeholder={t("START_TYPING_TO_SELECT_YOUR_CITY")}
												value={props.value}
											/>
										)}
									/>
									{errors.city?.type === "required" && (
										<span className="input-error">{t("CITY_IS_REQUIRED")}</span>
									)}
									<input
										type="hidden"
										value={justCity}
										name="justCity"
										ref={register({ required: true })}
									/>
									{!errors.city && errors.justCity?.type === "required" && (
										<span className="input-error">
											{t(
												"PLEASE_START_TYPING_AND_THEN_CHOOSE_THE_CITY_FROM_THE_DROPDOWN_LIST"
											)}
										</span>
									)}
								</div>
								<div className="buttons">
									<button
										onClick={() => back()}
										className="white"
										type="button"
									>
										{t("BACK")}
									</button>
									<button
										className="blue"
										// disabled={!vin || !date || !city}
										type="submit"
									>
										{t("NEXT")}
									</button>
								</div>
							</form>
						)}
						{alertType === "case" && (
							<form onSubmit={handleSubmit(next)}>
								<h2>{t("ENTER_CASE_ID")}</h2>
								<p>{t("ENTER_CASE_ID_WITH_ACCIDENT_DATE_AND_CITY")}.</p>
								<br />
								<br />
								<div className="input-container">
									<label>{t("CASE_ID")}:</label>
									{getValues("caseID") && (
										<span className="input-ok">
											<img src="/assets/checked.svg" />
										</span>
									)}
									<input
										placeholder={t("CASE_ID_NUMBER")}
										name="caseID"
										type="text"
										ref={register({
											required: true
										})}
										className={errors.caseID ? "err" : ""}
									/>
									{errors.caseID?.type === "required" && (
										<span className="input-error">
											{t("CASE_ID_IS_REQUIRED")}
										</span>
									)}
								</div>

								<div
									className="input-container half hide-desktop"
									onClick={() => setMobileDatePicker(true)}
								>
									<label>{t("DATE_OF_ACCIDENT")}:</label>
									{getValues("date") && getValues("date").length === 10 && (
										<span className="input-ok">
											<img src="/assets/checked.svg" />
										</span>
									)}
									<div
										className={errors.date ? "err fake-input" : "fake-input"}
										style={{ pointerEvents: "none" }}
									>
										{getValues("date") || "Tap to pick"}
									</div>
									{errors.date?.type === "required" && (
										<span className="input-error">{t("DATE_IS_REQUIRED")}</span>
									)}
								</div>

								<Controller
									name="date"
									control={control}
									rules={{ required: true, validate: true }}
									defaultValue={new Date()}
									render={(props) => (
										<DatePicker
											dateConfig={dateConfig}
											value={
												props.value ? dayjs(props.value).toDate() : new Date()
											}
											isOpen={mobileDatePicker}
											onSelect={(d) => {
												props.onChange(dayjs(d).format("YYYY-MM-DD"));
												setMobileDatePicker(false);
											}}
											onCancel={() => setMobileDatePicker(false)}
											confirmText={t("CONFIRM")}
											cancelText={t("CANCEL")}
											theme="ios"
											max={new Date()}
											headerFormat={"MM/DD/YYYY"}
										/>
									)}
								/>

								<div className="input-container half hide-mobile">
									<label>{t("DATE_OF_ACCIDENT")}:</label>
									{getValues("date") && getValues("date").length === 10 && (
										<span className="input-ok">
											<img src="/assets/checked.svg" />
										</span>
									)}
									<input
										type="date"
										name="date"
										max={dayjs().format("YYYY-MM-DD")}
										ref={register({ required: true })}
										className={errors.date ? "err" : ""}
										placeholder="MM/DD/YYYY"
									/>
									{errors.date?.type === "required" && (
										<span className="input-error">{t("DATE_IS_REQUIRED")}</span>
									)}
								</div>
								<div className="input-container">
									<label>{t("CITY_OF_ACCIDENT")}:</label>
									{getValues("city") && getValues("justCity") && (
										<span className="input-ok">
											<img src="/assets/checked.svg" />
										</span>
									)}
									<Controller
										name="city"
										control={control}
										rules={{ required: true, validate: true }}
										defaultValue={""}
										render={(props) => (
											<Autocomplete
												className={errors.city || errors.justCity ? "err" : ""}
												componentRestrictions={{ country: "us" }}
												onChange={(e) => {
													props.onChange(e.target.value);
													setJustCity("");
													setAddress("");
												}}
												onPlaceSelected={(e) => {
													findCity(e);
													props.onChange(e.formatted_address);
												}}
												types={["(regions)"]}
												placeholder={t("START_TYPING_TO_SELECT_YOUR_CITY")}
												value={props.value}
											/>
										)}
									/>
									{errors.city?.type === "required" && (
										<span className="input-error">{t("CITY_IS_REQUIRED")}</span>
									)}
									<input
										type="hidden"
										value={justCity}
										name="justCity"
										ref={register({ required: true })}
									/>
									{!errors.city && errors.justCity?.type === "required" && (
										<span className="input-error">
											{t(
												"PLEASE_START_TYPING_AND_THEN_CHOOSE_THE_CITY_FROM_THE_DROPDOWN_LIST"
											)}
										</span>
									)}
								</div>
								<div className="buttons">
									<button
										onClick={() => back()}
										className="white"
										type="button"
									>
										{t("BACK")}
									</button>
									<button className="blue" type="submit">
										{t("NEXT")}
									</button>
								</div>
							</form>
						)}
						{alertType === "know" && (
							<form onSubmit={handleSubmit(next)}>
								<div className={mapClicked && step === 1 ? "hide-mobile" : ""}>
									<h2 className="hide-mobile">
										{t("ZOOM_AND_CLICK_ON_THE_MAP")}
									</h2>
									<h2 className="hide-desktop">
										{t("ZOOM_AND_TOUCH_THE_MAP_TO_DROP_A_PIN")}
									</h2>
									<p>
										{t(
											"USE_THE_MAP_TO_LOCATE_WHERE_THE_ACCIDENT_HAPPENED_WITHIN_A_10_MILE_RADIUS"
										)}
										.
									</p>
									<br />
									<MapPicker
										setLocation={(l) =>
											setValue("location", l, { shouldValidate: true })
										}
										setAddress={(a) =>
											setValue("city", a, { shouldValidate: true })
										}
										onPlaceSelected={(a) => findCity(a)}
										height={300}
									/>
									{errors.location?.type === "required" && (
										<span
											className="input-error"
											style={{ marginBottom: 0, marginTop: 10 }}
										>
											{t("PLEASE_SELECT_LOCATION_ON_THE_MAP")}
										</span>
									)}

									<div
										className="buttons hide-desktop"
										style={{
											position: "absolute",
											width: "70%",
											bottom: 65,
											left: "15%"
										}}
									>
										<button
											onClick={() => back()}
											className="white"
											type="button"
											data-testid="know-back-step1"
										>
											{t("BACK")}
										</button>
										<button
											className="blue"
											type="button"
											data-testid="map-next"
											disabled={getValues("location") ? false : true}
											style={{ opacity: 1 }}
											onClick={() => {
												if (mapClicked) setStep(1);
												else
													Swal.fire(
														"",
														t("TOUCH_THE_MAP_TO_DROP_A_PIN"),
														"info"
													);
											}}
										>
											{t("NEXT")}
										</button>
									</div>
								</div>
								<div className={step === 0 ? "hide-mobile" : ""}>
									<h2 className="hide-desktop">{t("ENTER_VEHICLE_DATA")}</h2>
									<p className="hide-desktop">
										{t("ENTER_VEHICLE_MAKE__VEHICLE_YEAR_AND_ACCIDENT_DATE")}.
									</p>
									<br />
									<br />
									<div className="input-container">
										<label style={{ zIndex: 1 }}>{t("VEHICLE_MAKE")}:</label>
										{getValues("vehicleMake") &&
											getValues("vehicleMake").value && (
												<span className="input-ok vehicle-make">
													<img src="/assets/checked.svg" />
												</span>
											)}
									</div>

									<Controller
										name="vehicleMake"
										control={control}
										rules={{ required: true, validate: true }}
										defaultValue={null}
										render={(props) => (
											<Select
												classNamePrefix={
													errors.vehicleMake
														? "err vehicle-list"
														: "vehicle-list"
												}
												options={vehicleMakerList.map((value) => {
													return { value, label: value };
												})}
												onChange={(e) => props.onChange(e)}
												placeholder={t(
													"START_TYPING_TO_FIND_YOUR_VEHICLE_MAKE"
												)}
												value={props.value}
												openMenuOnClick={false}
												openMenuOnFocus={false}
												isClearable
											/>
										)}
									/>
									{errors.vehicleMake?.type === "required" && (
										<span className="input-error" style={{ marginTop: 6 }}>
											{t("VEHICLE_MAKE_IS_REQUIRED")}
										</span>
									)}
									<br />
									<br />
									<div className="input-container half">
										<label>{t("VEHICLE_YEAR")}:</label>
										{getValues("vehicleYear") &&
											getValues("vehicleYear").length === 4 && (
												<span className="input-ok">
													<img src="/assets/checked.svg" />
												</span>
											)}
										<input
											type="number"
											name="vehicleYear"
											maxLength="4"
											className={errors.vehicleYear ? "err" : ""}
											ref={register({ required: true, min: 1900, max: getActualYear() })}
											data-testid="vehicle-year"
										/>
										{errors.vehicleYear?.type === "required" && (
											<span className="input-error">
												{t("YEAR_IS_REQUIRED")}
											</span>
										)}
										{(errors.vehicleYear?.type === "max" ||
											errors.vehicleYear?.type === "min") && (
											<span className="input-error">{t("YEAR_NOT_VALID")}</span>
										)}
									</div>
									<div
										className="input-container half hide-desktop"
										onClick={() => setMobileDatePicker(true)}
									>
										<label>{t("DATE_OF_ACCIDENT")}:</label>
										{getValues("date") && getValues("date").length === 10 && (
											<span className="input-ok">
												<img src="/assets/checked.svg" />
											</span>
										)}
										<div
											className={errors.date ? "err fake-input" : "fake-input"}
											style={{ pointerEvents: "none" }}
										>
											{getValues("date") || "Tap to pick"}
										</div>
										{errors.date?.type === "required" && (
											<span className="input-error">
												{t("DATE_IS_REQUIRED")}
											</span>
										)}
									</div>

									<Controller
										name="date"
										control={control}
										rules={{ required: true, validate: true }}
										defaultValue={new Date()}
										render={(props) => (
											<DatePicker
												dateConfig={dateConfig}
												value={
													props.value ? dayjs(props.value).toDate() : new Date()
												}
												isOpen={mobileDatePicker}
												onSelect={(d) => {
													props.onChange(dayjs(d).format("YYYY-MM-DD"));
													setMobileDatePicker(false);
												}}
												onCancel={() => setMobileDatePicker(false)}
												confirmText={t("CONFIRM")}
												cancelText={t("CANCEL")}
												theme="ios"
												max={new Date()}
												headerFormat={"MM/DD/YYYY"}
											/>
										)}
									/>
									<div className="input-container half hide-mobile">
										<label>{t("DATE_OF_ACCIDENT")}:</label>
										{getValues("date") && getValues("date").length === 10 && (
											<span className="input-ok">
												<img src="/assets/checked.svg" />
											</span>
										)}
										<input
											type="date"
											name="date"
											max={dayjs().format("YYYY-MM-DD")}
											ref={register({ required: true })}
											className={errors.date ? "err" : ""}
											placeholder="MM/DD/YYYY"
										/>
										{errors.date?.type === "required" && (
											<span className="input-error">
												{t("DATE_IS_REQUIRED")}
											</span>
										)}
									</div>
									<div className="buttons">
										<button
											onClick={() => {
												back();
												setStep(0);
											}}
											className="white"
											type="button"
											data-testid="know-back"
										>
											{t("BACK")}
										</button>
										<button className="blue" type="submit">
											{t("NEXT")}
										</button>
									</div>
								</div>
							</form>
						)}
					</div>
				)}
			</div>
			<div style={{ height: 60 }} className="hide-mobile" />
			<div style={{ height: 10 }} className="hide-desktop" />
			<p style={{ textAlign: "center" }}>
				<img src="/assets/ssl.svg" width="150" />
			</p>
		</div>
	);
}
