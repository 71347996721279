import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactDOM from "react-dom";
import axios from "axios";
import "./style.scss";
import "./Pdf.scss";
import Footer from "./components/Footer";
const Home = lazy(() => import("./pages/Home"));
const Results = lazy(() => import("./pages/Results"));
const FindMyReport = lazy(() => import("./pages/FindMyReport"));
const NotifyMe = lazy(() => import("./pages/NotifyMe"));
const Detailed = lazy(() => import("./pages/Detailed"));
const AccidentsNearMe = lazy(() => import("./pages/AccidentsNearMe"));
import ContextProvider from "./context/ContextProvider";
import Navigation from "./components/Navigation";
import Portal from "./Portal";
import ColdConvert from "./pages/ColdConvert";
import CampLeJeUne from "./pages/CampLejeune";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en.json";
import es from "./locales/es.json";
import WorkersCompensation from "./pages/WorkersCompensation";
import RequestAttorney from "./pages/RequestAttorney";
import MedicalCompensation from "./pages/MedicalCompensation";
import RequestTruckAttorney from "./pages/RequestTruckAttorney";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

window.toggleMenu = () => {
	if (document.getElementsByTagName("nav")[0].classList.contains("show")) {
		document.getElementsByTagName("nav")[0].classList.remove("show");
	} else {
		document.getElementsByTagName("nav")[0].classList.add("show");
	}
};

window.setCookie = (name, value, hours) => {
	var expires = "";
	if (hours) {
		var date = new Date();
		date.setTime(date.getTime() + hours * 60 * 60 * 1000);
		expires = "; expires=" + date.toUTCString();
	}
	if (!value) expires = "; expires=Thu, 01 Jan 1970 00:00:00 UTC";
	document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

function App() {
	const urlParams = new URLSearchParams(window.location.search);
	const lang = urlParams.get("lang");
	const embed = window.location.href.includes('embed');
	i18n
		.use(LanguageDetector)
		.use(initReactI18next) // passes i18n down to react-i18next
		.init({
			// the translations
			// (tip move them in a JSON file and import them,
			// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
			resources: { en, es },
			// lng: "en", // if you're using a language detector, do not define the lng option
			fallbackLng: "en",
			lng: lang || "",
			react: {
				transSupportBasicHtmlNodes: true,
				transKeepBasicHtmlNodesFor: ["span", "br", "strong", "small", "em"]
			},
			interpolation: {
				escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
			}
		});

	return (
		<ContextProvider>
			<Navigation />
			<Router>
				<Suspense fallback={<div />}>
					<Switch>
						<Route exact path="/" component={Home} />
						<Route exact path="/accident/:id" component={Detailed} />
						<Route exact path="/accident/:id/:vin" component={Detailed} />
						<Route
							exact
							path="/notify-me"
							component={() => (
								<Portal>
									<NotifyMe />
								</Portal>
							)}
						/>
						<Route
							exact
							path="/alert-me"
							component={() => (
								<Portal>
									<NotifyMe />
								</Portal>
							)}
						/>
						<Route
							exact
							path="/find-my-report"
							component={() => (
								<Portal>
									<FindMyReport />
								</Portal>
							)}
						/>
						<Route
							exact
							path="/calculate-your-accident-claim-value"
							component={() => (
								<Portal>
									<ColdConvert />
								</Portal>
							)}
						/>
						<Route
							exact
							path="/request-attorney"
							component={() => (
								<Portal>
									<RequestAttorney />
								</Portal>
							)}
						/>
						<Route
							exact
							path="/fb/request-attorney"
							component={() => (
								<Portal>
									<RequestAttorney />
								</Portal>
							)}
						/>
						<Route
							exact
							path="/request-truck-attorney"
							component={() => (
								<Portal>
									<RequestTruckAttorney />
								</Portal>
							)}
						/>
						<Route
							exact
							path="/medical-compensation"
							component={() => (
								<Portal>
									<MedicalCompensation />
								</Portal>
							)}
						/>
						<Route
							exact
							path="/camp-lejeune-compensation-calculator"
							component={() => (
								<Portal>
									<CampLeJeUne />
								</Portal>
							)}
						/>
						<Route
							exact
							path="/workers-compensation"
							component={() => (
								<Portal>
									<WorkersCompensation />
								</Portal>
							)}
						/>
						<Route exact path="/pdf/:id" component={Detailed} />
						<Route exact path="/business/:id/:userId" component={Detailed} />
						<Route exact path="/results" component={Results} />
						<Route exact path="/accidents-near-me" component={AccidentsNearMe} />
						<Route exact path="/accidents-near-me/embed" component={AccidentsNearMe} />
						<Route exact path="/:id" component={Home} />
					</Switch>
				</Suspense>
				{!embed && <Footer />}
			</Router>
		</ContextProvider>
	);
}
ReactDOM.render(<App />, document.getElementById("root"));
