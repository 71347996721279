import React, { useState, useEffect, useRef } from "react";
import ContactInformationCheck from "../components/ContactInformationCheck";
import clsx from "clsx";
import Axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import Checkbox from "../components/Checkbox";
import LeJeJuneQuestions from "../components/LeJeJuneQuestions";
import GaugeChart from "react-gauge-chart";

const preferredTimes = [
	"Call ASAP",
	"9am – 10am",
	"11am – 12pm",
	"1pm – 2pm",
	"3pm – 4pm",
	"4pm – 5pm",
	"Call me after 5 pm"
];

const cancelReasons = [
	"I'm just looking for my report",
	"I don’t need to speak with anyone",
	"I’m just looking for information",
	"I already have a lawyer",
	"Other"
];

const scoreTitles = [
	"Low Case Value Determined!",
	"Medium Case Value Determined!",
	"High Case Value Determined!",
	"High Case Value Determined!"
];

const scoreDegrees = [
	"Low degree",
	"Medium degree",
	"High degree",
	"High degree"
];

const scorePercents = [0.12, 0.36, 0.75, 0.95];

export default function CampLeJeUne() {
	const steps = 3;
	const [step, setStep] = useState(0); //0
	const [userData, setUserData] = useState({});
	const [formData, setFormData] = useState({});
	const [q1, setQ1] = useState(undefined);
	const [q2, setQ2] = useState(undefined);
	const [q3, setQ3] = useState(undefined);
	const [legalConsult, setLegalConsult] = useState(false);
	const [ajax, setAjax] = useState(false);
	// const [atFault, setAtFault] = useState(false);
	const leadTypesRef = useRef({});
	const history = useHistory();
	// const [finalAgree, setFinalAgree] = useState(true);
	const [preferredTime, setPreferredTime] = useState("Call ASAP");
	const scrollRef = useRef();
	const resultIds = useRef();

	const calcScore = (data) => {
		if (data.q1 || data.q2 || data.q3) return 3;
		return 0;
	};

	const complete = () => {
		// console.log("COMPLETE");
		// console.log(formData);
		// console.log(userData);

		setAjax(true);
		if (
			!Object.keys(leadTypesRef.current).length ||
			leadTypesRef.current.notInterested
		)
			leadTypesRef.current = { CALawyer: "AM" };

		// console.log(leadTypesRef.current);

		const firstName = userData.name.split(" ")[0];
		const lastName = userData.name.split(" ")[1]
			? userData.name.split(" ")[1]
			: "";
		const email = userData.email;
		const phone = userData.phone;

		let data = {
			q1,
			q2,
			q3,
			firstName,
			lastName,
			email,
			phone,
			legal_consult: legalConsult,
			trusted_form_cert: window.cert?.xxTrustedFormCertUrl || "",
			gclidcrm: window.gclidcrm || "",
		};

		// console.log(data);
		// setTimeout(() => {
		Axios.post("/leads/create-camp-lejeune", data)
			.then((d) => {
				gtag("event", "campLejeune-success", {
					event_category: "campLejeune",
					event_label: legalConsult
						? "campLejeune-success-hot"
						: "campLejeune-success-cold"
				});

				setAjax(false);
				setStep(5);
				if (d.data.resultIds) resultIds.current = d.data.resultIds;
				if (!legalConsult)
					window.location.href =
						"https://myaccident.org/mass-tort/camp-lejeune";
			})
			.catch((e) => {
				Swal.fire("Network Error.", String(e), "error");
				setAjax(false);
			});
		// }, 1000);
	};

	useEffect(() => {
		const STEPtoURL = {
			0: "info-screen",
			1: "accident-details",
			2: "user-info",
			2.5: "phone-confirm",
			3.5: "additional-info",
			4.5: "submit",
			5: "success"
		};
		if (STEPtoURL[step] && window.gtag) {
			// console.log(step, STEPtoURL[step]);
			gtag("event", "page_view", {
				page_title: "Find My Report",
				page_location: "/find-my-report/" + STEPtoURL[step],
				page_path: "/find-my-report/" + STEPtoURL[step]
			});
		}
	}, [step]);

	useEffect(() => {
		if (legalConsult) complete();
	}, [legalConsult]);
	

	const updatePreferredTime = async () => {
		// console.log(resultIds.current, preferredTime);
		try {
			await Axios.put("leads/update/time", {
				ids: resultIds.current,
				preferredCallTime: preferredTime
			});
			Swal.fire(
				"Thank you.",
				"Your preferred time for call has been updated.",
				"success"
			).then(() => {
				window.location.href = "https://myaccident.org/mass-tort/camp-lejeune";
			});
		} catch (e) {
			console.log(e);
		}
	};

	const showCancelModal = async () => {
		window.Swal = Swal;
		window.cancelReason = 0;
		const confirm = await Swal.fire({
			title: "Please select a reason for cancelation:",
			showCancelButton: false,
			confirmButtonText: "Cancel my request",
			confirmButtonColor: "var(--green)",
			customClass: "cancelRequest",
			html: `
			<br />
					<a class="close" onClick="Swal.close()">
						<img src="/assets/icon-close.png" alt="close" />
					</a>
			<label><input type="radio" value="0" name="cancelReason" checked onChange="window.cancelReason=0" >${cancelReasons[0]}</label>
			<label><input type="radio" value="1" name="cancelReason" onChange="window.cancelReason=1">${cancelReasons[1]}</label>
			<label><input type="radio" value="2" name="cancelReason" onChange="window.cancelReason=2">${cancelReasons[2]}</label>
			<label><input type="radio" value="3" name="cancelReason" onChange="window.cancelReason=3">${cancelReasons[3]}</label>
			<label><input type="radio" value="4" name="cancelReason" onChange="window.cancelReason=4">${cancelReasons[4]}</label>
			`
		});
		if (confirm) {
			console.log("reason:", cancelReasons[window.cancelReason]);
			try {
				await Axios.put("leads/update/cancel", {
					ids: resultIds.current,
					cancelReason: cancelReasons[window.cancelReason]
				});
			} catch (e) {
				console.log(e);
			}

			Swal.fire({
				title: `Thank you ${
					userData.name.split(" ")[0]
				}, Your request for a free legal consultation has been canceled.`,
				customClass: "cancelRequestSuccess",
				html: `<p style="color:var(--blue)">Our team is still hard at work trying to locate your accident report, we will notify
				you as soon as we match your search parameters with the corresponding police report.</p>
				<br />
				When should I consult with an attorney?<br />
				<br />
				<a href="https://myaccident.org/request-free-legal-consultation" target="_blank" style="color:#000; font-weight:bold">Learn more about when you need to speak with a lawyer.</a>
				`
			}).then(() => (window.location.href = "/"));
		}
	};

	return (
		<div
			id="notify-me"
			className={ajax ? "steps-container ajax" : "steps-container"}
		>
			{/* {step} */}
			{step === 0 ? (
				<div
					className={step >= 0 ? "steps-page first show" : "steps-page first"}
					data-testid="step0"
				>
					<a
						className="close lejeune"
						onClick={() =>
							(window.location.href =
								"https://myaccident.org/mass-tort/camp-lejeune")
						}
					>
						<img src="/assets/icon-close.png" alt="close" />
					</a>
					<br className="hide-mobile" />
					<br className="hide-mobile" />
					<br className="hide-mobile" />
					<div className="row lejeune">
						<div>
							<img
								src="/assets/alert-intro-lejeune.png"
								style={{ maxWidth: "100%" }}
								className="hide-mobile"
							/>
							<br />
							<br />
							<h2 style={{ color: "#30a7fb" }}>
								Camp Lejeune Justice Act of 2021
							</h2>
							Camp Lejeune Justice Act of 2022 provides compensation for
							illnesses that may have developed while living or serving at Camp
							Lejeune from 1953-1987
							<br />
							<br />
							<h2 style={{ color: "#30a7fb" }}>Who Qualifies?</h2>
							1.Military Personnel, Civilian Personnel, Family Members exposed
							to contaminated water In the Camp Lejeune or MCAS New River{" "}
							<strong>Between 1953 and 1987.</strong>
							<br />
							<br />
							2. Family members representing deceased relatives that were
							exposed to contaminated water
							<br />
							<br />
							<h2 style={{ color: "#30a7fb" }}>What Happened?</h2>
							Marines, military employees, and their families have developed
							serious illnesses from exposure to the contaminated water at Camp
							Lejeune.
							<div className="line hide-desktop" />
							<br className="hide-desktop" />
							<button
								onClick={() => setStep(1)}
								type="button"
								className="hide-desktop green"
								style={{ width: "100%" }}
							>
								Continue
							</button>
							<br className="hide-desktop" />
						</div>
						<div className="intro">
							<br className="hide-mobile" />
							<br className="hide-mobile" />
							<br className="hide-mobile" />
							<h1>
								<strong>
									Did You Or A Loved One Develop A Serious Illness Due To Toxic
									Drinking Water At Camp Lejeune?
								</strong>
							</h1>
							<img
								src="/assets/alert-intro-lejeune.png"
								style={{ maxWidth: "100%" }}
								className="hide-desktop"
							/>
							<br />
							<p style={{ color: "#000", fontSize: 18 }}>
								Use the MyAccident.org calculator tool to determine your
								potential case value to find out if qualify for{" "}
								<strong>a free case consultation.</strong>
							</p>
							<br />
							<div className="line" />
							<p>
								Provide some basic information about your experience with Camp
								Lejeune and our team will do our best to help connect you with a
								free legal consultation.
							</p>
							<button
								onClick={() => setStep(1)}
								type="button"
								data-testid="step1-continue"
							>
								Continue
							</button>
						</div>
					</div>
				</div>
			) : (
				<>
					{/* {step} */}
					<div className="steps">
						<div className={step >= 1 ? "step active" : "step"}>
							<strong>Step 1</strong>
							Accident Information
						</div>
						<div className={step > 2 ? "step active" : "step"}>
							<strong>Step 2</strong>
							Contact Information
						</div>
						{/* <div className={step > 3 ? "step active" : "step"}>
							<strong>Step 3</strong>
							Additional info
						</div> */}
						<div className={step >= 3 ? "step active" : "step"}>
							<strong>Step 3</strong>
							View Results
						</div>
					</div>
					<div className="steps-progress">
						<div
							className="bar"
							style={{ width: ((step - 1) / steps) * 100 + "%" }}
						/>
					</div>
				</>
			)}
			<div
				className={step >= 1 ? "steps-page show" : "steps-page"}
				data-testid="step1"
			>
				<div className={clsx({ "hide-mobile": q1 && q2 && q3 })}>
					<h1>Help us calculate your claim value</h1>
					<br />
					<p>
						Find out if you qualify for <em>free</em> evaluation.
						<strong className="orange-back">
							Complete the following questions:
						</strong>
					</p>
				</div>

				<LeJeJuneQuestions
					q1={q1}
					q2={q2}
					q3={q3}
					setQ1={(d) => {
						setQ1(d);
						setStep(1.5);
					}}
					setQ2={(d) => {
						setQ2(d);
						setStep(1.5);
					}}
					setQ3={(d) => {
						setQ3(d);
						setStep(1.5);
					}}
					back={() => {
						setStep(0);
						setQ1(undefined);
						setQ2(undefined);
						setQ3(undefined);
					}}
					next={(data) => {
						setStep(2);
						setFormData(data);
						setLegalConsult(undefined);
						// console.log(data);
					}}
				/>
			</div>

			<div
				className={step >= 2 ? "steps-page show" : "steps-page"}
				data-testid="step2"
			>
				{step < 3 && (
					<div style={{ maxWidth: 500, margin: "auto" }}>
						<ContactInformationCheck
							back={() => setStep(1.5)}
							next={(data) => {
								setUserData(data);
								setStep(3.5);
							}}
							smsSent={() => setStep(2.5)}
						/>
					</div>
				)}

				<p style={{ textAlign: "center", marginTop: 60 }}>
					<img src="/assets/ssl.svg" width="150" />
				</p>
			</div>

			{/* <div
				className={step >= 3 ? "steps-page show" : "steps-page"}
				data-testid="step3"
			>
				<MainQuestions
					atFault={atFault}
					setAtFault={setAtFault}
					hasLawyer={hasLawyer}
					setHasLawyer={setHasLawyer}
				/>

				<div className="buttons">
					<button
						onClick={() => {
							setStep(2);
							// setInjured(undefined);
							setHasLawyer(undefined);
							setAtFault(undefined);
						}}
						className="white"
						type="button"
					>
						Back
					</button>
					<button
						className="blue"
						onClick={() => {
							// if (!hasLawyer && !atFault) {
							// 	setStep(4.2);
							// } else {
							setStep(4.2);
							// }
						}}
						disabled={
							// injured === undefined ||
							hasLawyer === undefined || atFault === undefined
						}
					>
						Next
					</button>
				</div>
			</div> */}

			<div
				className={step >= 3 ? "steps-page show" : "steps-page"}
				ref={scrollRef}
				data-testid="step4"
			>
				<h1>Your Claim Value</h1>
				<br />
				<p>
					Find out if you qualify for a <em>free</em> evaluation.
				</p>
				<br />

				<div id="cold-convert-confirm">
					<section>
						<div className="left">
							<h3 style={{ textAlign: "center" }}>
								{scoreTitles[calcScore(formData)]}
							</h3>
							<br />
							{/* {scorePercents[calcScore(formData)]} */}
							<div className="lejeune-gauge">
								<GaugeChart
									nrOfLevels={3}
									arcsLength={[0.25, 0.25, 0.5]}
									colors={["#5BE12C", "#F5CD19", "#EA4228"].reverse()}
									percent={scorePercents[calcScore(formData)]}
									arcPadding={0.02}
									arcWidth={0.3}
									cornerRadius={0}
									hideText={true}
								/>
							</div>
						</div>
						<div className="right hide-mobile">
							<p>Your case value is determined by:</p>
							<ul>
								{q1 ? (
									<li>Stationed at Camp Lejeune between 1953 and 1987</li>
								) : null}
								{q2 ? <li>Serious illness developed</li> : null}
								{q3 ? (
									<li>
										30 cumulative days between August 1st, 1953 through December
										31st, 198
									</li>
								) : null}
							</ul>
						</div>
					</section>
					<div style={{ height: 1, background: "#DDD", margin: "20px 0" }} />
					{/* <h3>To get a more detailed evaluation, request a call below.</h3>
					<div>
						<br />
						Based on your responses, you qualify for a{" "}
						<strong>free case evaluation</strong> to help determine your{" "}
						<strong>accident claim value</strong>. A five-minute call can tell
						you if you are eligible for a cash settlement.
					</div> */}

					<div
						className="row"
						style={{
							alignItems: "flex-start",
							marginTop: 40,
							marginBottom: 20
						}}
					>
						<div>
							<div className="row" style={{ alignItems: "center" }}>
								<img
									src="/assets/callme.png"
									style={{ width: 100 }}
									className="hide-mobile"
								/>
								<h3 style={{ opacity: 0.7, fontSize: 21 }}>
									Call me to register for the Camp Lejeune settlement
								</h3>
							</div>
						</div>
						<div style={{ flex: 1 }}>
							<div className="row yes-no">
								<img
									src="/assets/callme.png"
									style={{
										width: 88,
										position: "relative",
										display: "inline",
										top: 10
									}}
									className="hide-desktop"
								/>
								<div
									onClick={() => {
										setLegalConsult(false);
									}}
									className={legalConsult === false ? "checked" : ""}
									data-testid="no"
								>
									NO
									<div className="yes-no-box">
										<img src="/assets/checked.svg" />
									</div>
								</div>
								<div
									onClick={() => {
										setLegalConsult(true);
									}}
									className={legalConsult ? "checked" : ""}
									data-testid="yes"
								>
									YES
									<div className="yes-no-box">
										<img src="/assets/checked.svg" />
									</div>
								</div>
								{/* <div
								style={{ minWidth: 120, paddingTop: 25 }}
								onClick={() => {
									setLegalConsult(false);
									setConfirm(true);
									next();
								}}
								data-testid="no"
							>
								<a>No, Thanks</a>
							</div> */}
							</div>
							<br />
							<small style={{ marginLeft: 10, display: "block" }}>
								By clicking “yes”, I agree to be contacted by one or more
								lawyers.
							</small>
						</div>
					</div>
					<div style={{ clear: "both" }} />
					{legalConsult === undefined ? (
						<small>
							By submitting this request, you agree that MyAccident.org can
							share this information with one of its sponsors so they can
							call/text you about your inquiry. You don't need to consent as
							condition of agreeing to hire a lawyer. You also agree to our{" "}
							<a href="https://myaccident.org/terms-of-use" target="_blank">
								Terms of Use.
							</a>
						</small>
					) : null}
					{legalConsult === true ? (
						<small>
							MyAccident.org is not a law firm, nor a lawyer referral service
							nor is it a substitute for hiring an attorney or law firm and does
							not provide legal advice. MyAccident.org does not endorse or
							recommend any participating Third-Party Legal Professionals that
							pay to participate in this advertising. An attorney-client
							relationship is not formed when calling the number on this site or
							filling out a form. Services are not available in all states or
							for all legal categories. All persons depicted in a photo or video
							are actors or models and not clients of any firm.
						</small>
					) : null}
					{legalConsult === false ? (
						<small>
							KEEPING MYACCIDENT.ORG FREE
							<br />
							This service is sponsored by legal, medical, insurance, vehicle
							recycle and collision repair professionals. By submitting this
							request, you agree to MyAccident.org sharing your contact
							information for the purposes of being contacted by one of our
							sponsors. Users may receive a follow up telephone call, including
							by automated dialing systems and artificial or pre-recorded voice
							messages, email or SMS contact by a sponsor to assist in your
							recovery process. There has been no analysis of the accident. The
							listed law firms have paid to participate, and the website does
							not evaluate the qualifications of law firms or lawyers. A law
							firm is assigned neutrally (e.g., jurisdiction) and at random; the
							fact a firm is assigned does not mean it is the “best” or “right
							lawyer” for the users needs or that the lawyer is preferred over
							the other lawyers. The selection is not an evaluation of the
							quality of the selected lawyer’s work or analysis of the users
							problem. Use of this service does not create a client/sponsor
							relationship. You also agree to our{" "}
							<a href="https://myaccident.org/terms-of-use" target="_blank">
								Terms of Use.
							</a>
							.
						</small>
					) : null}
				</div>

				<div style={{ height: 1, background: "#DDD", margin: "40px 0" }} />
				<div className="buttons confirm">
					<button
						disabled={legalConsult === undefined}
						className="green"
						onClick={() => complete()}
					>
						Submit
					</button>
					<button className="outline" onClick={() => setStep(2)}>
						Back
					</button>
				</div>
			</div>
			<div
				className={step >= 5 ? "steps-page show" : "steps-page"}
				data-testid="step6"
			>
				{legalConsult ? (
					<div style={{ textAlign: "center" }}>
						<img src="/assets/swal-success.png" width="80" />
						<a
							style={{ position: "absolute", right: 20 }}
							href="https://myaccident.org/mass-tort/camp-lejeune"
						>
							<img src="/assets/icon-close.png" />
						</a>
						<br />
						<br />
						<h2 style={{ textAlign: "center" }}>
							{userData.name?.split(" ")[0]}, you’re all set!
						</h2>
						<br />
						<div className="row">
							<div style={{ textAlign: "center" }}>
								Our team will reach out at your selected time to get you
								connected to the help you need.
							</div>
						</div>
						<br />
						<div style={{ height: 1, background: "#CCC" }}></div>
						<br />
						<p style={{ textAlign: "center", color: "var(--orange)" }}>
							You requested to speak to someone about Camp Lejejune, if you have
							a preference
							<br /> select a time that’s best for you:
						</p>
						<br />
						{preferredTimes.map((v) => (
							<div
								style={{
									maxWidth: "170px",
									margin: "10px auto",
									cursor: "pointer",
									display: "flex"
								}}
								onClick={() => setPreferredTime(v)}
								key={v}
							>
								<span style={{ marginRight: 8 }}>
									<Checkbox checked={preferredTime === v} />
								</span>
								{v}
							</div>
						))}
						<br />
						<p style={{ textAlign: "center", fontSize: 12 }}>
							MyAccident.org operates between 9 am and 5 pm Monday to Friday.
							Requests made over the weekend will be scheduled for Monday.{" "}
						</p>
						<br />
						<div style={{ height: 1, background: "#CCC" }}></div>
						<br />
						<div style={{ textAlign: "center" }}>
							<button
								style={{ margin: "auto", minWidth: 250 }}
								className="green"
								disabled={!preferredTime}
								onClick={() => updatePreferredTime()}
							>
								Submit
							</button>
							{/* <br />
							<a
								style={{
									fontSize: 12,
									textDecoration: "underline",
									color: "#666"
								}}
								onClick={() => showCancelModal()}
							>
								Cancel Request
							</a>
							<br />
							<br /> */}
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
}
