/* istanbul ignore file */
import { useEffect } from "react";
import { createPortal } from "react-dom";

const Portal = ({ children }) => {
	const mount = document.getElementById("modal-root");
	const el = document.createElement("div");
	el.classList.add("modal");

	useEffect(() => {
		mount.appendChild(el);
		setTimeout(() => {
			el.classList.add("show");
		}, 10);
		return () => {
			el.classList.remove("show");
			setTimeout(() => {
				mount.removeChild(el);
			}, 300);
		};
	}, [el, mount]);

	return createPortal(children, el);
};

export default Portal;
