import React, { useState } from "react";
import AppContext from ".";
import * as vehicles from "./Vehicles";

const ContextProvider = ({ children }) => {
	const [modal, setModal] = useState("");

	const context = {
		modal,
		setModal,
		vehicleMakerList: vehicles.vehicleMakerList
	};

	return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

export default ContextProvider;
