import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import ContactInformationCheck from "../components/ContactInformationCheck";
import clsx from "clsx";
import AlertTypeChooser from "../components/AlertTypeChooser";
import GaugeChart from "react-gauge-chart";
import Checkbox from "../components/Checkbox";
import Axios from "axios";
import Swal from "sweetalert2";

const preferredTimes = [
	"Call ASAP",
	"9am – 10am",
	"11am – 12pm",
	"1pm – 2pm",
	"3pm – 4pm",
	"4pm – 5pm",
	"Call me after 5 pm"
];

const scoreTitles = [
	"Low Case Value Determined!",
	"Medium Case Value Determined!",
	"High Case Value Determined!",
	"Highest Case Value Determined!"
];

const scorePercents = [0.12, 0.36, 0.75, 0.95];

const scoreDegrees = [
	"Low degree",
	"Medium degree",
	"High degree",
	"Highest degree"
];

export default function RequestTruckAttorney() {
	const steps = 3;
	const [step, setStep] = useState(0);
	const [ajax, setAjax] = useState(false);
	const [mobileHover, setMobileHover] = useState(null);
	const [userData, setUserData] = useState({});
	const [formData, setFormData] = useState({
		injuries: 1,
		commercial: undefined,
		fault: undefined,
		earnings: 1,
		medical: 1,
		pain: 1
	});

	const [alertType, setAlertType] = useState("");
	const [state, setState] = useState("");
	const [zip, setZip] = useState("");
	const [justCity, setJustCity] = useState("");
	const [address, setAddress] = useState("");
	const [preferredTime, setPreferredTime] = useState("Call ASAP");

	const resultIds = useRef();

	const { t, i18n } = useTranslation();
	const toggleMobileHover = (w) => {
		if (w === mobileHover) setMobileHover(null);
		else setMobileHover(w);
	};

	const calcScore = (data) => {
		if (data.fault === true) return 0;
		else {
			if (
				data.injuries === 2 ||
				data.earnings === 2 ||
				data.medical === 2 ||
				data.pain === 2 ||
				data.commercial
			)
				return 3;
			else {
				if (
					data.injuries === 1 ||
					data.earnings === 1 ||
					data.medical === 1 ||
					data.pain === 1
				)
					return 2;
				else {
					return 1;
				}
			}
		}
		// else if (data.fault === false) return 2;
		// else return 1;
	};

	const updatePreferredTime = async () => {
		try {
			await Axios.put("leads/update/time", {
				ids: resultIds.current,
				preferredCallTime: preferredTime
			});
			Swal.fire(
				t("THANK_YOU"),
				t("YOUR_PREFERRED_TIME_FOR_CALL_HAS_BEEN_UPDATED"),
				"success"
			).then(() => {
				window.location.href = "/";
			});
		} catch (e) {
			console.log(e);
		}
	};

	const complete = () => {
		setAjax(true);

		const headers = {
			'page-referrers': window.location.href
		}

		Axios.post(
			"/leads/create",
			{
				firstName: userData.name.split(" ")[0],
				lastName: userData.name.split(" ")[1],
				phone: userData.phone,
				email: userData.email,
				legal_consult: true,
				leadTypes: { CALawyer: "AM" },
				injured: formData.injuries > 0,
				state,
				zip,
				extraDetails: {
					accidentDetails: { ...formData, ...userData }
				},
				source: "truck",
				language: i18n.language,
				trusted_form_cert: window.cert?.xxTrustedFormCertUrl || "",
				gclidcrm: window.gclidcrm || "",
			},
			{ withCredentials: true, headers },
		).then((d) => {
			setAjax(false);
			if (d.data.resultIds) resultIds.current = d.data.resultIds;
			setStep(4);
		});
	};

	useEffect(() => {
		/* istanbul ignore next */
		window.setCookie(
			"ref",
			window.location.search ? window.location.search : "",
			1
		);
	}, []);

	/*
	steps
*/
	const step0 = () => (
		<div
			className={step >= 0 ? "steps-page first show" : "steps-page first"}
			data-testid="step0"
		>
			<div className="row">
				<div className="left-image">
					<img
						src="/assets/request-truck-attorney.gif"
						style={{ width: "100%" }}
						alt="request an attorney"
					/>
				</div>
				<div className="intro">
					<h1>
						<Trans
							i18nKey={
								"WE'VE_HELPED_HUNDREDS_OF_THOUSANDS_OF_ACCIDENT_TRUCK_VICTIMS"
							}
						/>
					</h1>
					<div className="steps-information">
						<h3>
							<strong>{t("HOW_IT_WORKS")}:</strong>
						</h3>
						<ol>
							<li>
								<Trans i18nKey={"PROVIDE_BASIC_DETAILS_ABOUT_YOUR_ACCIDENT"} />
							</li>
							<li>
								<Trans i18nKey={"WE_ESTIMATE_YOUR_CLAIM_VALUE"} />
							</li>
							<li>
								<Trans i18nKey={"WE_CONNECT_YOU_TO_A_LOCAL_ATTORNEY"} />
							</li>
						</ol>
					</div>
					<div className="line" />
					<button
						onClick={() => setStep(1)}
						type="button"
						data-testid="step1-continue"
						className="calculate-btn"
					>
						{t("CALCULATE_MY_CLAIM")}
					</button>
					<br />
					<p style={{ textAlign: "center", marginBottom: 12 }}>
						<img src="/assets/ssl.svg" width="92" />
					</p>
					<small>
						<Trans i18nKey={"KEEPING_MYACCIDENT_ORG_FREE"} />{" "}
						<a href="https://myaccident.org/terms-of-use" target="_blank">
							{t("TERMS_OF_USE")}.
						</a>
					</small>
				</div>
			</div>
		</div>
	);

	const laterStepsHeader = () => (
		<>
			<div className="steps">
				<div className={step >= 1 ? "step active" : "step"}>
					<strong>{t("STEP_1")}</strong>
					{t("ACCIDENT_INFORMATION")}
				</div>
				<div className={step > 2 ? "step active" : "step"}>
					<strong>{t("STEP_2")}</strong>
					{t("CONTACT_INFORMATION")}
				</div>
				<div className={step >= 3 ? "step active" : "step"}>
					<strong>{t("STEP_3")}</strong>
					{t("VIEW_RESULTS")}
				</div>
			</div>
			<div className="steps-progress">
				<div
					className="bar"
					style={{ width: ((step - 1) / steps) * 100 + "%" }}
				/>
			</div>
		</>
	);

	const step1 = () => (
		<div
			className={step >= 1 ? "steps-page show" : "steps-page"}
			data-testid="step1"
		>
			<h1>{t("HELP_US_CALCULATE_YOUR_ACCIDENT_CLAIM_VALUE")}</h1>
			<br />
			<p>
				<Trans i18nKey={"FIND_OUT_IF_YOU_QUALIFY"} />
				<strong className="orange-back">
					{t("COMPLETE_THE_FOLLOWING_QUESTIONS_")}
				</strong>
			</p>

			<div id="cold-convert-questions">
				<section>
					<div className="left">
						<div>
							<h3>{t("WERE_YOU_AT_FAULT_FOR_THIS_ACCIDENT_")}</h3>
							<div className="row yes-no">
								<div
									className={formData.fault === false ? "checked" : ""}
									onClick={() =>
										setFormData({
											...formData,
											...{ fault: false }
										})
									}
								>
									{t("NO")}
									<div className="yes-no-box">
										<img src="/assets/checked.svg" style={{ marginRight: 0 }} />
									</div>
								</div>
								<div
									className={formData.fault ? "checked" : ""}
									onClick={() =>
										setFormData({
											...formData,
											...{ fault: true }
										})
									}
								>
									{t("YES")}
									<div className="yes-no-box">
										<img src="/assets/checked.svg" style={{ marginRight: 0 }} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className={mobileHover === 1 ? "right hover" : "right"}
						onClick={() => toggleMobileHover(1)}
					>
						<img src="/assets/cold-convert/fault.png" />
						<span>i</span>
						<div onClick={() => setMobileHover(null)}>
							<h4>{t("AT_FAULT_STATUS")} ($)</h4>
							{t("FAULT_STATUS_IS")}
						</div>
					</div>
				</section>

				<section>
					<div className="left">
						<div>
							<h3>{t("WAS_THERE_A_COMMERCIAL_VEHICLE_INVOLVED_")}</h3>
							<div className="row yes-no">
								<div
									className={formData.commercial === false ? "checked" : ""}
									onClick={() =>
										setFormData({
											...formData,
											...{ commercial: false }
										})
									}
								>
									{t("NO")}
									<div className="yes-no-box">
										<img src="/assets/checked.svg" style={{ marginRight: 0 }} />
									</div>
								</div>
								<div
									className={formData.commercial ? "checked" : ""}
									onClick={() =>
										setFormData({
											...formData,
											...{ commercial: true }
										})
									}
								>
									{t("YES")}
									<div className="yes-no-box">
										<img src="/assets/checked.svg" style={{ marginRight: 0 }} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className={mobileHover === 2 ? "right hover" : "right"}
						onClick={() => toggleMobileHover(2)}
					>
						<img src="/assets/cold-convert/commercial.png" />
						<span>i</span>
						<div onClick={() => setMobileHover(null)}>
							<h4>{t("COMMERCIAL_VEHICLE_INVOLVEMENT")} ($)</h4>
							{t("IF_YOUR_ACCIDENT_INVOLVED_A_COMMERCIAL_VEHICLE")}
						</div>
					</div>
				</section>
				<section>
					<div className="left">
						<h3>
							{t("WHAT_DEGREE_OF_INJURIES_DID_YOU_SUSTAIN_IN_YOUR_ACCIDENT_")}
						</h3>
						<input
							type="range"
							min={0}
							max={2}
							step={1}
							list="ticks"
							value={formData.injuries}
							onChange={(e) =>
								setFormData({
									...formData,
									...{ injuries: Number(e.target.value) }
								})
							}
						/>
						<datalist id="ticks" style={{ "--list-length": 3 }}>
							<option value="0">{t("LOW")}</option>
							<option value="1">{t("MEDIUM")}</option>
							<option value="2">{t("HIGH")}</option>
						</datalist>
					</div>
					<div
						className={mobileHover === 3 ? "right hover" : "right"}
						onClick={() => toggleMobileHover(3)}
					>
						<img src="/assets/cold-convert/injuries.png" />
						<span>i</span>
						<div onClick={() => setMobileHover(null)}>
							<h4>{t("INJURIES_AND_MEDICAL_EXPENSES")} ($)</h4>
							{t("WHY_IS_INJURY_IMPORTANT")}
						</div>
					</div>
				</section>
			</div>
			<div className="buttons" style={{ maxWidth: 700, margin: "50px auto 0" }}>
				<button onClick={() => setStep(0)} className="white" type="button">
					{t("BACK")}
				</button>

				<button
					className="green"
					onClick={() => setStep(1.5)}
					disabled={
						formData.fault === undefined || formData.commercial === undefined
					}
				>
					{t("NEXT")}
				</button>
			</div>
			<p style={{ textAlign: "center", marginTop: 40 }}>
				<img src="/assets/ssl.svg" width="150" />
			</p>
		</div>
	);

	const step15 = () => (
		<div
			className={step >= 1.5 ? "steps-page show" : "steps-page"}
			data-testid="step15"
		>
			<h1>{t("HELP_US_CALCULATE_YOUR_ACCIDENT_CLAIM_VALUE")}</h1>
			<br />
			<p>
				<Trans i18nKey={"FIND_OUT_IF_YOU_QUALIFY"} />
				<strong className="orange-back">
					{t("COMPLETE_THE_FOLLOWING_QUESTIONS_")}
				</strong>
			</p>

			<div id="cold-convert-questions">
				<section>
					<div className="left">
						<h3>{t("DID_YOU_EXPERIENCE_A_LOSS_OF_INCOME_")}</h3>
						<input
							type="range"
							min={0}
							max={2}
							step={1}
							list="ticks"
							value={formData.earnings}
							onChange={(e) =>
								setFormData({
									...formData,
									...{ earnings: Number(e.target.value) }
								})
							}
						/>
						<datalist id="ticks" style={{ "--list-length": 3 }}>
							<option value="0">{t("LOW")}</option>
							<option value="1">{t("MEDIUM")}</option>
							<option value="2">{t("HIGH")}</option>
						</datalist>
					</div>
					<div
						className={mobileHover === 4 ? "right hover" : "right"}
						onClick={() => toggleMobileHover(4)}
					>
						<img src="/assets/cold-convert/earnings.png" />
						<span>i</span>
						<div onClick={() => setMobileHover(null)}>
							<h4>{t("LOST_EARNINGS")} ($)</h4>
							{t("ACCIDENTS_CAN_COMMONLY_LEAD_TO_A_LOSS_OF_EARNINGS")}
						</div>
					</div>
				</section>

				<section>
					<div className="left">
						<h3>{t("DO_YOU_EXPECT_FUTURE_MEDICAL_EXPENSES_")}</h3>
						<input
							type="range"
							min={0}
							max={2}
							step={1}
							list="ticks"
							value={formData.medical}
							onChange={(e) =>
								setFormData({
									...formData,
									...{ medical: Number(e.target.value) }
								})
							}
						/>
						<datalist id="ticks" style={{ "--list-length": 3 }}>
							<option value="0">{t("LOW")}</option>
							<option value="1">{t("MEDIUM")}</option>
							<option value="2">{t("HIGH")}</option>
						</datalist>
					</div>
					<div
						className={mobileHover === 5 ? "right hover" : "right"}
						onClick={() => toggleMobileHover(5)}
					>
						<img src="/assets/cold-convert/medical.png" />
						<span>i</span>
						<div onClick={() => setMobileHover(null)}>
							<h4>{t("ESTIMATED_FUTURE_MEDICAL_EXPENSES")}</h4>
							{t("IF_YOUR_INJURIES_WILL_REQUIRE_ONGOING_MEDICAL_TREATMENT")}
						</div>
					</div>
				</section>

				<section>
					<div className="left">
						<h3>{t("DID_YOU_EXPERIENCE_PAIN_AND_SUFFERING_")}</h3>
						<input
							type="range"
							min={0}
							max={2}
							step={1}
							list="ticks"
							value={formData.pain}
							onChange={(e) =>
								setFormData({
									...formData,
									...{ pain: Number(e.target.value) }
								})
							}
						/>
						<datalist id="ticks" style={{ "--list-length": 3 }}>
							<option value="0">{t("LOW")}</option>
							<option value="1">{t("MEDIUM")}</option>
							<option value="2">{t("HIGH")}</option>
						</datalist>
					</div>
					<div
						className={mobileHover === 6 ? "right hover" : "right"}
						onClick={() => toggleMobileHover(6)}
					>
						<img src="/assets/cold-convert/pain.png" />
						<span>i</span>
						<div onClick={() => setMobileHover(null)}>
							<h4>{t("MULTIPLIER_FOR_GENERAL_DAMAGES")}</h4>
							{t("THERE_IS_A_MULTIPLIER_USED_TO_ESTIMATE_YOUR_GENERAL_DAMAGES")}
							<ul>
								<li>{t("PAIN_AND_SUFFERING")}</li>
								<li>{t("LOSS_OF_ENJOYMENT_OF_LIFE")}</li>
								<li>{t("EMOTIONAL_DISTRESS")}</li>
								<li>{t("LOSS_OF_CONSORTIUM__COMPANIONSHIP_")}</li>
							</ul>
						</div>
					</div>
				</section>
			</div>
			<div className="buttons" style={{ maxWidth: 700, margin: "50px auto 0" }}>
				<button onClick={() => setStep(1)} className="white" type="button">
					{t("BACK")}
				</button>

				<button
					className="green"
					onClick={() => setStep(1.75)}
					disabled={
						formData.earnings === undefined ||
						formData.medical === undefined ||
						formData.pain === undefined
					}
				>
					{t("NEXT")}
				</button>
			</div>
			<p style={{ textAlign: "center", marginTop: 40 }}>
				<img src="/assets/ssl.svg" width="150" />
			</p>
		</div>
	);

	const step175 = () => (
		<div
			className={step >= 1.75 ? "steps-page show" : "steps-page"}
			data-testid="step2"
		>
			<div className={clsx({ "hide-mobile": alertType })}>
				<h1>{t("HELP_US_LOCATE_YOUR_ACCIDENT_REPORT")}</h1>
				<br />
				<p>
					{t("HELP_US_LOCATE_YOUR_ACCIDENT_REPORT")}.
					<strong className="orange-back">
						{t("CHOOSE_ONE_OPTION_BELOW")}:
					</strong>
				</p>
			</div>

			<AlertTypeChooser
				alertType={alertType}
				setAddress={setAddress}
				setJustCity={setJustCity}
				justCity={justCity}
				setState={setState}
				setZip={setZip}
				setAlertType={(a) => {
					setAlertType(a);
					setStep(1.75);
				}}
				back={() => {
					setStep(1.5);
					setAlertType("");
					setAddress("");
					setJustCity("");
					setFormData({
						...formData,
						...{ city: "", vin: "", caseID: "", justCity: "", date: "" }
					});
				}}
				next={(data) => {
					setStep(2);
					setFormData({ ...formData, ...data });
				}}
			/>
		</div>
	);
	const step2 = () => (
		<div
			className={step >= 2 ? "steps-page show" : "steps-page"}
			data-testid="step25"
		>
			<div style={{ maxWidth: 500, margin: "auto" }}>
				<ContactInformationCheck
					back={() => {
						setFormData({
							...formData,
							...{ city: "", vin: "", caseID: "", justCity: "", date: "" }
						});
						setStep(1.75);
					}}
					next={(data) => {
						setUserData(data);
						setStep(3);
					}}
					smsSent={() => setStep(2.5)}
				/>
			</div>
			<p style={{ textAlign: "center", marginTop: 60 }}>
				<img src="/assets/ssl.svg" width="150" />
			</p>
		</div>
	);

	const step3 = () => (
		<div
			className={step >= 3 ? "steps-page show" : "steps-page"}
			data-testid="step3"
		>
			<h1>{t("YOUR_ACCIDENT_CLAIM_VALUE")}</h1>
			<br />
			<p>
				<Trans i18nKey={"FIND_OUT_IF_YOU_QUALIFY"} />
			</p>
			<br />
			<br />
			<div id="cold-convert-confirm">
				<section>
					<div className="left">
						<h3 style={{ textAlign: "center" }}>
							{t(scoreTitles[calcScore(formData)])}
						</h3>
						<br />
						<GaugeChart
							nrOfLevels={3}
							arcsLength={[0.25, 0.25, 0.5]}
							colors={["#5BE12C", "#F5CD19", "#EA4228"].reverse()}
							percent={scorePercents[calcScore(formData)]}
							arcPadding={0.02}
							arcWidth={0.3}
							cornerRadius={0}
							hideText={true}
						/>
					</div>
					<div className="right">
						<p>{t("YOUR_CASE_VALUE_IS_DETERMINED_BY_")}</p>
						<ul>
							<li>
								{formData.fault ? t("AT_FAULT_PARTY") : t("NOT_AT_FAULT")}
							</li>
							{formData.commercial ? (
								<li>{t("COMMERCIAL_VEHICLE_INVOLVEMENT")}</li>
							) : null}
							<li>
								{t(scoreDegrees[formData.medical])} {t("OF_MEDICAL_EXPENSES")}
							</li>
							<li>
								{t(scoreDegrees[formData.injuries])} {t("OF_INJURY_STATUS")}
							</li>
							<li>
								{t(scoreDegrees[formData.earnings])} {t("OF_LOSS_OF_INCOME")}
							</li>
							<li>
								{t(scoreDegrees[formData.pain])} {t("OF_PAIN_AND_SUFFERING")}
							</li>
						</ul>
					</div>
				</section>
				<div style={{ height: 1, background: "#DDD", margin: "40px 0" }} />
				<div
					className="row"
					style={{
						alignItems: "flex-start",
						marginTop: 40,
						marginBottom: 20
					}}
				>
					<div>
						<div className="call-me">
							<div>
								<img src="/assets/callme.png" className="hide-mobile" />
							</div>
						</div>

						<div className="buttons confirm">
							<button className="green" onClick={complete}>
								{t("MENU_REQUEST")}
							</button>
							<button className="outline" onClick={() => setStep(2.5)}>
								{t("BACK")}
							</button>
						</div>
					</div>
				</div>

				<div style={{ clear: "both" }} />
				<small>
					{t("BY_SUBMITTING_THIS_REQUEST")}{" "}
					<a href="https://myaccident.org/terms-of-use" target="_blank">
						{t("TERMS_OF_USE")}.
					</a>
				</small>
			</div>
		</div>
	);

	const step4 = () => (
		<div
			className={step >= 4 ? "steps-page show" : "steps-page"}
			data-testid="step4"
		>
			<div style={{ textAlign: "center" }}>
				<img src="/assets/swal-success.png" width="60" />
				<br />
				<br />
				<h2 style={{ textAlign: "center" }}>
					{t("YOU_RE_ALL_SET", { name: userData.name?.split(" ")[0] })}
				</h2>
				<br />
				<div className="row">
					<div style={{ textAlign: "center" }}>
						{t("YOU_WILL_BE_CONTACTED_SHORTLY")}
					</div>
				</div>
				<br />
				<div style={{ height: 1, background: "#CCC" }}></div>
				<br />
				<div style={{ textAlign: "center" }}>
					<img src="/assets/swal-success.png" width="60" />
					<h2>
						{t("YOUR_REQUEST_FOR_A_FREE_LEGAL_CONSULTATION_HAS_BEEN_CONFIRMED")}
					</h2>
				</div>
				<p
					style={{
						textAlign: "center",
						color: "var(--orange)",
						marginTop: "16px"
					}}
				>
					<Trans
						i18nKey={"YOU_REQUESTED_TO_SPEAK_TO_SOMEONE_ABOUT_YOUR_ACCIDENT"}
					/>
				</p>
				<br />
				{preferredTimes.map((v) => (
					<div
						style={{
							maxWidth: i18n.language === "en" ? "170px" : "270px",
							margin: "10px auto",
							cursor: "pointer",
							display: "flex"
						}}
						onClick={() => setPreferredTime(v)}
						key={v}
					>
						<span style={{ marginRight: 8 }}>
							<Checkbox checked={preferredTime === v} />
						</span>
						{t(v)}
					</div>
				))}
				<br />
				<p style={{ textAlign: "center", fontSize: 12 }}>
					{t("MYACCIDENT_ORG_OPERATES_BETWEEN_9_AM_AND_5_PM")}
				</p>
				<br />
				<div style={{ height: 1, background: "#CCC" }}></div>
				<br />
				<div style={{ textAlign: "center" }}>
					<button
						style={{ margin: "auto", minWidth: 250 }}
						className="green"
						disabled={!preferredTime}
						onClick={() => updatePreferredTime()}
					>
						{preferredTime === "Call ASAP" ? t("DONE") : t("SUBMIT")}
					</button>
				</div>
			</div>
		</div>
	);

	return (
		<>
			<a
				className="close request-attorney-close"
				href={
					step >= 3
						? "/"
						: "https://myaccident.org/request-free-legal-consultation"
				}
			>
				<img src="/assets/icon-close.png" alt="close" />
			</a>
			<div
				id="notify-me"
				className={
					ajax
						? "steps-container ajax request-attorney"
						: "steps-container request-attorney"
				}
			>
				{step === 0 ? step0() : laterStepsHeader()}

				{step === 1 ? step1() : null}
				{step === 1.5 ? step15() : null}
				{step === 1.75 ? step175() : null}
				{step === 2 || step === 2.5 ? step2() : null}
				{step === 3 ? step3() : null}
				{step === 4 ? step4() : null}
			</div>
		</>
	);
}
