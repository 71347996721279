import React from "react";
import { useTranslation } from "react-i18next";

export default function Navigation() {
	const { t, i18n } = useTranslation();
	return (
		<nav>
			<a className="close" onClick={() => toggleMenu()}>
				<img src="/assets/icon-close.png" alt="close" />
			</a>
			<a href="/">
				<img src="/assets/logo.png" className="logo" />
			</a>
			<a href="/find-my-report">{t("MENU_FIND_MY")}</a>
			<a href="/alert-me">{t("MENU_NOTIFY_ME")}</a>
			<a
				target="_blank"
				rel="noopener noreferrer"
				href="https://myaccident.org/request-free-legal-consultation"
			>
				{t("MENU_REQUEST")}
			</a>
			<a
				href="https://myaccident.org/about"
				target="_blank"
				rel="noopener noreferrer"
			>
				{t("MENU_ABOUT")}
			</a>
			{i18n.language === "es" ? (
				<a
					onClick={() => i18n.changeLanguage("en")}
					style={{ position: "absolute", bottom: 20 }}
				>
					English
				</a>
			) : (
				<a
					onClick={() => i18n.changeLanguage("es")}
					style={{ position: "absolute", bottom: 20 }}
				>
					Spanish
				</a>
			)}

			{/* <p>
				<strong>Resources</strong>

				<a href="https://myaccident.org/texas-accident-reports" target="_blank">
					Texas Accident Reports
				</a>
				<a href="https://myaccident.org/website-survey" target="_blank">
					Website Survey
				</a> */}
			{/* <span onClick={this.openAds}>Advertise With Us</span> */}
			{/* <a href="https://business.myaccident.org" target="_blank">
					Business Portal
				</a>
			</p> */}
		</nav>
	);
}
