import React from "react";

export default function Checkbox({ checked }) {
	return (
		<div
			style={{
				border: checked ? "var(--blue) 2px solid" : "var(--gray) 2px solid",
				width: 20,
				height: 20,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				transition: "all 0.3s",
				borderRadius: 3,
				display: "inline-block",
				cursor: "pointer",
				backgroundColor: checked ? "var(--blue)" : ""
			}}
		>
			<img src="/assets/checked.svg" style={{ width: 16, height: 16 }} />
		</div>
	);
}
