import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer() {
	const { t, i18n } = useTranslation();

	return (
		<div id="footer">
			<div className="container">
				{t("ALL_RIGHTS")} •{" "}
				<a href="https://myaccident.org/terms-of-use" target="_blank">
					{t("TERMS_OF_USE")}
				</a>{" "}
				•{" "}
				<a href="https://myaccident.org/privacy-policy" target="_blank">
					{t("PRIVACY_POLICY")}
				</a>{" "}
				•{" "}
				<a href="https://myaccident.org/disclaimer" target="_blank">
					{t("DISCLAIMER")}
				</a>{" "}
				• {t("REVIEW_US")} •{" "}
				{i18n.language === "es" ? (
					<a onClick={() => i18n.changeLanguage("en")}>English</a>
				) : (
					<a onClick={() => i18n.changeLanguage("es")}>Spanish</a>
				)}
			</div>
		</div>
	);
}
