import React, { useState, useEffect, useRef } from "react";
import ContactInformationCheck from "../components/ContactInformationCheck";
import Axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import Checkbox from "../components/Checkbox";
import { Trans, useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-mobile-datepicker";
import dayjs from "dayjs";
import Autocomplete from "react-google-autocomplete";

const monthMap = {
	1: "Jan",
	2: "Feb",
	3: "Mar",
	4: "Apr",
	5: "May",
	6: "Jun",
	7: "Jul",
	8: "Aug",
	9: "Sep",
	10: "Oct",
	11: "Nov",
	12: "Dec"
};

const dateConfig = {
	year: {
		format: "YYYY",
		caption: "Year",
		step: 1
	},
	month: {
		format: (value) => monthMap[value.getMonth() + 1],
		caption: "Mon",
		step: 1
	},
	date: {
		format: "DD",
		caption: "Day",
		step: 1
	}
};

const preferredTimes = [
	"Call ASAP",
	"9am – 10am",
	"11am – 12pm",
	"1pm – 2pm",
	"3pm – 4pm",
	"4pm – 5pm",
	"Call me after 5 pm"
];

export default function WorkersCompensation() {
	const steps = 3;
	const [step, setStep] = useState(0); //0
	const [userData, setUserData] = useState({});
	const [formData, setFormData] = useState({});
	const [legalConsult, setLegalConsult] = useState(false);
	const [ajax, setAjax] = useState(false);
	const leadTypesRef = useRef({});
	const history = useHistory();
	const [preferredTime, setPreferredTime] = useState("Call ASAP");
	const scrollRef = useRef();
	const resultIds = useRef();
	const { t } = useTranslation();
	const [mobileDatePicker, setMobileDatePicker] = useState(false);
	const [justCity, setJustCity] = useState("");
	const [state, setState] = useState("");
	const [zip, setZip] = useState("");
	const [confirm, setConfirm] = useState(false);
	const [hasLawyer, setHasLawyer] = useState(true);
	const [injured, setInjured] = useState(false);
	const [injuryType, setInjuryType] = useState("");

	const {
		register,
		unregister,
		handleSubmit,
		errors,
		control,
		setValue,
		getValues
	} = useForm({ mode: "onBlur" });

	const complete = () => {
		// console.log("COMPLETE");
		// console.log(formData);
		// console.log(userData);
		// console.log(injuryType, state, justCity, zip);

		setAjax(true);
		if (
			!Object.keys(leadTypesRef.current).length ||
			leadTypesRef.current.notInterested
		)
			leadTypesRef.current = { CALawyer: "AM" };

		// console.log(leadTypesRef.current);

		const firstName = userData.name.split(" ")[0];
		const lastName = userData.name.split(" ")[1]
			? userData.name.split(" ")[1]
			: "";
		const email = userData.email;
		const phone = userData.phone;

		let data = {
			firstName,
			lastName,
			email,
			phone,
			legal_consult: legalConsult,
			trusted_form_cert: window.cert?.xxTrustedFormCertUrl || "",
			damage: injuryType,
			state,
			city: justCity,
			zip,
			lead_type: "WorkComp",
			date: formData.date,
			source: "WorkComp",
			injured,
			gclidcrm: window.gclidcrm || "",
		};

		console.log(data);

		const headers = {
			'page-referrers': window.location.href
		}

		// setTimeout(() => {
		Axios.post("/leads/create", data, { headers })
			.then((d) => {
				gtag("event", "WorkComp-success", {
					event_category: "WorkComp",
					event_label: legalConsult
						? "WorkComp-success-hot"
						: "WorkComp-success-cold"
				});

				setAjax(false);
				setStep(5);
				if (d.data.resultIds) resultIds.current = d.data.resultIds;
				if (!legalConsult)
					window.location.href =
						"https://myaccident.org/mass-tort/camp-lejeune";
			})
			.catch((e) => {
				Swal.fire("Network Error.", String(e), "error");
				setAjax(false);
			});
		// }, 1000);
	};

	useEffect(() => {
		const STEPtoURL = {
			0: "info-screen",
			1: "accident-details",
			2: "user-info",
			2.5: "phone-confirm",
			3.5: "additional-info",
			4.5: "submit",
			5: "success"
		};
		if (STEPtoURL[step] && window.gtag) {
			// console.log(step, STEPtoURL[step]);
			gtag("event", "page_view", {
				page_title: "Find My Report",
				page_location: "/find-my-report/" + STEPtoURL[step],
				page_path: "/find-my-report/" + STEPtoURL[step]
			});
		}
	}, [step]);

	const updatePreferredTime = async () => {
		// console.log(resultIds.current, preferredTime);
		try {
			await Axios.put("leads/update/time", {
				ids: resultIds.current,
				preferredCallTime: preferredTime
			});
			Swal.fire(
				"Thank you.",
				"Your preferred time for call has been updated.",
				"success"
			).then(() => {
				window.location.href = "https://myaccident.org/mass-tort/camp-lejeune";
			});
		} catch (e) {
			console.log(e);
		}
	};

	const findCity = (v) => {
		// console.log(v);
		let gotZip;
		let city;
		let sstate;
		setJustCity(v.formatted_address);
		if (v.address_components) {
			v.address_components.forEach((vv) => {
				if (vv.types.includes("administrative_area_level_1")) {
					// console.log("FOUND STATE", vv.short_name);
					setState(vv.short_name);
					sstate = vv.short_name;
				}
				if (vv.types.includes("postal_code")) {
					// console.log("FOUND ZIP", vv.short_name);
					setZip(vv.short_name);
					gotZip = true;
				}
				if (vv.types.includes("locality") && vv.types.includes("political")) {
					// console.log("FOUND CITY", vv.short_name);
					city = vv.short_name;
					setJustCity(city);
				}
			});
		}
		if (!gotZip && city && sstate) {
			// console.log(
			// 	"FETCH ZIP FROM API:",
			// 	v.geometry.location.lat(),
			// 	v.geometry.location.lng()
			// );
			Axios.get(
				`accident/zip?lat=${v.geometry.location.lat()}&lng=${v.geometry.location.lng()}&city=${city}&state=${sstate}`
			).then((d) => {
				if (d.data.success && d.data.area) {
					// console.log("FOUND ZIP FROM API:", d.data.area.code);
					setZip(d.data.area.code);
				}
			});
		}
	};

	return (
		<div
			id="notify-me"
			className={ajax ? "steps-container ajax" : "steps-container"}
		>
			{/* {step} */}
			{step === 0 ? (
				<div
					className={step >= 0 ? "steps-page first show" : "steps-page first"}
					data-testid="step0"
				>
					<a
						className="close lejeune"
						onClick={() =>
							(window.location.href =
								"https://myaccident.org/workers-compensation")
						}
					>
						<img src="/assets/icon-close.png" alt="close" />
					</a>
					<br className="hide-mobile" />
					<br className="hide-mobile" />
					<br className="hide-mobile" />
					<div className="row lejeune">
						<div>
							<img
								src="/assets/workers-compensation.png"
								style={{ maxWidth: "100%" }}
								className="hide-mobile"
							/>
						</div>
						<div className="intro">
							<br className="hide-mobile" />
							<br className="hide-mobile" />
							<br className="hide-mobile" />
							<h1>
								<Trans i18nKey={"STRONG_ACCIDENTS_AT_WORK"} />
							</h1>
							<img
								src="/assets/workers-compensation.png"
								style={{ maxWidth: "100%" }}
								className="hide-desktop"
							/>
							<div className="line" />

							<p>
								<Trans i18nKey={"IN_EVERY_STATE__WORKERS"} />
							</p>
							<button
								onClick={() => setStep(1)}
								type="button"
								data-testid="step1-continue"
							>
								{t("CONTINUE")}
							</button>
						</div>
					</div>
				</div>
			) : (
				<>
					{/* {step} */}
					<div className="steps">
						<div className={step >= 1 ? "step active" : "step"}>
							<strong>{t("STEP_1")}</strong>
							{t("ACCIDENT_DETAILS")}
						</div>
						<div className={step > 2 ? "step active" : "step"}>
							<strong>{t("STEP_2")}</strong>
							{t("CONTACT_INFORMATION")}
						</div>
						<div className={step >= 3 ? "step active" : "step"}>
							<strong>{t("STEP_3")}</strong>
							{t("CONFIRM_")}
						</div>
					</div>
					<div className="steps-progress">
						<div
							className="bar"
							style={{ width: ((step - 1) / steps) * 100 + "%" }}
						/>
					</div>
				</>
			)}
			<div
				className={step >= 1 ? "steps-page show" : "steps-page"}
				data-testid="step1"
			>
				<div style={{ maxWidth: 800, margin: "auto" }}>
					<h1 style={{ textAlign: "center" }}>
						{t("SHARE_SOME_INFORMATION_ABOUT_YOUR_WORKPLACE_INJURY")}
					</h1>
					<div style={{ height: 1, background: "#DDD", marginTop: 40 }} />
					{/* <br />
					<p>
						Find out if you qualify for <em>free</em> evaluation.
						<strong className="orange-back">
							 the following questions:
						</strong>
					</p> */}
				</div>
				<div style={{ maxWidth: 800, margin: "auto" }}>
					<div>
						<br />
						<form
							onSubmit={handleSubmit((data) => {
								// console.log(data);
								setFormData(data);
								setStep(2);
							})}
						>
							<div className="input-container">
								<label>{t("TYPE_OF_INJURY_")}</label>
								{getValues("injuryType") && (
									<span className="input-ok">
										<img src="/assets/checked.svg" />
									</span>
								)}
								<select
									value={injuryType}
									onChange={(e) => setInjuryType(e.target.value)}
									ref={register({ required: true })}
									className={errors.injuryType ? "err" : ""}
									name="injuryType"
								>
									<option value="">Select an option</option>
									<option value="Back or Neck Pain">
										{t("BACK_OR_NECK_PAIN")}
									</option>
									<option value="Broken Bones">{t("BROKEN_BONES")}</option>
									<option value="Cuts and Bruises">
										{t("CUTS_AND_BRUISES")}
									</option>
									<option value="Headaches">{t("HEADACHES")}</option>
									<option value="Other">{t("OTHER")}</option>
								</select>
								{errors.injuryType?.type === "required" && (
									<span className="input-error">
										{t("INJURY_TYPE_REQUIRED")}
									</span>
								)}
							</div>
							<div
								className="input-container hide-desktop"
								onClick={() => setMobileDatePicker(true)}
							>
								<label>{t("DATE_OF_ACCIDENT")}:</label>
								{getValues("date") && getValues("date").length === 10 && (
									<span className="input-ok">
										<img src="/assets/checked.svg" />
									</span>
								)}
								<div
									className={errors.date ? "err fake-input" : "fake-input"}
									style={{ pointerEvents: "none" }}
								>
									{getValues("date") || "Tap to pick"}
								</div>
								{errors.date?.type === "required" && (
									<span className="input-error">{t("DATE_IS_REQUIRED")}</span>
								)}
							</div>
							<Controller
								name="date"
								control={control}
								rules={{ required: true, validate: true }}
								defaultValue={new Date()}
								render={(props) => (
									<DatePicker
										dateConfig={dateConfig}
										value={
											props.value ? dayjs(props.value).toDate() : new Date()
										}
										isOpen={mobileDatePicker}
										onSelect={(d) => {
											props.onChange(dayjs(d).format("YYYY-MM-DD"));
											setMobileDatePicker(false);
										}}
										onCancel={() => setMobileDatePicker(false)}
										confirmText={t("CONFIRM")}
										cancelText={t("CANCEL")}
										theme="ios"
										max={new Date()}
										headerFormat={"MM/DD/YYYY"}
									/>
								)}
							/>
							<div className="input-container hide-mobile">
								<label>{t("DATE_OF_ACCIDENT")}:</label>
								{getValues("date") && getValues("date").length === 10 && (
									<span className="input-ok">
										<img src="/assets/checked.svg" />
									</span>
								)}
								<input
									type="date"
									name="date"
									max={dayjs().format("YYYY-MM-DD")}
									ref={register({ required: true })}
									className={errors.date ? "err" : ""}
									placeholder="MM/DD/YYYY"
								/>
								{errors.date?.type === "required" && (
									<span className="input-error">{t("DATE_IS_REQUIRED")}</span>
								)}
							</div>
							<div
								style={{
									fontSize: 12,
									background: "#FFC107",
									padding: 3,
									borderRadius: 4,
									marginTop: -20,
									textAlign: "center",
									color: "#000"
								}}
							>
								{t(
									"IF_YOUR_INJURY_OCCURRED_OVER_A_SUSTAINED_PERIOD__ENTER_THE_THE_MOST_RECENT_DATE_YOU_WERE_INJURED_"
								)}
							</div>
							<br />
							<br />
							<br />
							<div className="input-container">
								<label>{t("CITY_OF_ACCIDENT")}:</label>
								{getValues("city") && getValues("justCity") && (
									<span className="input-ok">
										<img src="/assets/checked.svg" />
									</span>
								)}
								{step >= 1 ? (
									<Controller
										name="city"
										control={control}
										rules={{ required: true, validate: true }}
										defaultValue={""}
										render={(props) => (
											<Autocomplete
												className={errors.city || errors.justCity ? "err" : ""}
												componentRestrictions={{ country: "us" }}
												onChange={(e) => {
													props.onChange(e.target.value);
													setJustCity("");
												}}
												onPlaceSelected={(e) => {
													findCity(e);
													props.onChange(e.formatted_address);
												}}
												types={["(regions)"]}
												placeholder={t("START_TYPING_TO_SELECT_YOUR_CITY")}
												value={props.value}
											/>
										)}
									/>
								) : null}
								{errors.city?.type === "required" && (
									<span className="input-error">{t("CITY_IS_REQUIRED")}</span>
								)}
								<input
									type="hidden"
									value={justCity}
									name="justCity"
									ref={register({ required: true })}
								/>
								{!errors.city && errors.justCity?.type === "required" && (
									<span className="input-error">
										{t(
											"PLEASE_START_TYPING_AND_THEN_CHOOSE_THE_CITY_FROM_THE_DROPDOWN_LIST"
										)}
									</span>
								)}
							</div>

							<div className="buttons">
								<button
									onClick={() => setStep(0)}
									className="white"
									type="button"
								>
									{t("BACK")}
								</button>
								<button
									className="blue"
									// disabled={!vin || !date || !city}
									type="submit"
								>
									{t("NEXT")}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>

			<div
				className={step >= 2 ? "steps-page show" : "steps-page"}
				data-testid="step2"
			>
				{step < 3 && (
					<div style={{ maxWidth: 500, margin: "auto" }}>
						<ContactInformationCheck
							back={() => setStep(1.5)}
							next={(data) => {
								setUserData(data);
								setStep(3.5);
							}}
							smsSent={() => setStep(2.5)}
						/>
					</div>
				)}

				<p style={{ textAlign: "center", marginTop: 60 }}>
					<img src="/assets/ssl.svg" width="150" />
				</p>
			</div>

			<div
				className={step >= 3 ? "steps-page show" : "steps-page"}
				ref={scrollRef}
				data-testid="step4"
			>
				<div
					style={{ maxWidth: 800, margin: "auto", position: "relative" }}
					className="important-message-screen"
				>
					<div className="why hide-mobile">
						<img
							src="/assets/workers-important-message.png"
							alt="A five-minute call can tell you if you are entitled to compensation."
						/>
						<br />
					</div>
					<div className="right">
						<h3 style={{ color: "var(--orange)" }}>
							{t("IMPORTANT_MESSAGE_ABOUT_YOUR_WORKPLACE_ACCIDENT_")}
						</h3>
						<br />
						<p style={{ fontSize: "1.1em" }}>
							<Trans i18nKey={"IN_EVERY_STATE__WORKERS"} />
						</p>

						<div
							className="row"
							style={{
								alignItems: "flex-start",
								marginTop: 40,
								marginBottom: 20
							}}
						>
							<div>
								<div className="row" style={{ alignItems: "center" }}>
									<img
										src="/assets/callme.png"
										style={{ width: 100 }}
										className="hide-mobile"
									/>
									<div>
										<h3 style={{ opacity: 0.7, fontSize: 21 }}>
											{t("REQUEST_A_FREE_LEGAL_CONSULTATION")}.
										</h3>
										<small className="hide-mobile">
											<Trans i18nKey={"BY_CLICKING_YES"} />
										</small>
									</div>
								</div>
							</div>
							<div style={{ flex: 1 }}>
								<div className="row yes-no">
									<img
										src="/assets/callme.png"
										style={{
											width: 88,
											position: "relative",
											display: "inline",
											top: 10
										}}
										className="hide-desktop"
									/>
									{/* <div
										onClick={() => {
											setLegalConsult(false);
											setConfirm(true);
										}}
										className={legalConsult === false ? "checked" : ""}
										data-testid="no"
									>
										{t("NO")}
										<div className="yes-no-box">
											<img src="/assets/checked.svg" />
										</div>
									</div> */}
									<div
										onClick={() => {
											setLegalConsult(true);
											setConfirm(true);
										}}
										className={legalConsult ? "checked" : ""}
										data-testid="yes"
									>
										{t("YES")}
										<div className="yes-no-box">
											<img src="/assets/checked.svg" />
										</div>
									</div>
									{/* <div
								style={{ minWidth: 120, paddingTop: 25 }}
								onClick={() => {
									setLegalConsult(false);
									setConfirm(true);
									next();
								}}
								data-testid="no"
							>
								<a>No, Thanks</a>
							</div> */}
								</div>
								<small
									className="hide-desktop"
									style={{
										width: 180,
										display: "block",
										margin: "20px auto 0"
									}}
								>
									<Trans i18nKey={"BY_CLICKING_YES"} />
								</small>
							</div>
						</div>

						{legalConsult ? (
							<div>
								<br />
								<h3>
									<Trans i18nKey={"BEFORE_WE"} />
								</h3>
								<br />
								<div
									className="row yes-no"
									style={{
										marginTop: 10,
										marginBottom: 10,
										alignItems: "center"
									}}
								>
									<div
										style={{ textAlign: "right" }}
										onClick={() => {
											setHasLawyer(
												hasLawyer === undefined ? false : !hasLawyer
											);
										}}
									>
										{t("I_AM_NOT_CURRENTLY_REPRESENTED_BY_A_LAWYER")}
									</div>
									<div
										onClick={() => {
											setHasLawyer(
												hasLawyer === undefined ? false : !hasLawyer
											);
										}}
										className={hasLawyer === false ? "checked" : ""}
										data-testid="hasLawyer"
										style={{ maxWidth: 125 }}
									>
										<div className="yes-no-box" style={{ marginLeft: 0 }}>
											<img src="/assets/checked.svg" />
										</div>
									</div>
								</div>
								<div
									className="row yes-no"
									style={{
										marginTop: 10,
										marginBottom: 10,
										alignItems: "center"
									}}
								>
									<div
										style={{ textAlign: "right" }}
										onClick={() => {
											setInjured(!injured);
										}}
									>
										{t("THERE_WERE_INJURIES_INVOLVED_IN_THIS_ACCIDENT")}
									</div>
									<div
										onClick={() => {
											setInjured(!injured);
										}}
										className={injured ? "checked" : ""}
										data-testid="injured"
										style={{ maxWidth: 125 }}
									>
										<div className="yes-no-box" style={{ marginLeft: 0 }}>
											<img src="/assets/checked.svg" />
										</div>
									</div>
								</div>
								<br />
								<br />
							</div>
						) : null}
					</div>
					<div style={{ clear: "both" }} />
					{legalConsult === undefined ? (
						<small>
							{t("BY_SUBMITTING_THIS_REQUEST")}{" "}
							<a href="https://myaccident.org/terms-of-use" target="_blank">
								{t("TERMS_OF_USE")}.
							</a>
						</small>
					) : null}
					{legalConsult === true ? (
						<small>{t("MYACCIDENT_ORG_IS_NOT_A_LAW_FIRM")}</small>
					) : null}
					{legalConsult === false ? (
						<small>
							<Trans i18nKey={"KEEPING_MYACCIDENT_ORG_FREE"} />{" "}
							<a href="https://myaccident.org/terms-of-use" target="_blank">
								{t("TERMS_OF_USE")}.
							</a>
							.
						</small>
					) : null}
					<div style={{ height: 1, background: "#DDD", margin: "40px 0" }} />
					<div className="buttons confirm">
						<button
							disabled={
								legalConsult === undefined ||
								!confirm ||
								(legalConsult && (hasLawyer || !injured))
							}
							className="green"
							onClick={() => {
								complete();
							}}
						>
							{legalConsult ? t("SUBMIT") : t("NEXT")}
						</button>
						<button className="outline" onClick={() => setStep(2)}>
							{t("BACK")}
						</button>
					</div>
				</div>
			</div>

			<div
				className={step >= 5 ? "steps-page show" : "steps-page"}
				data-testid="step6"
			>
				{legalConsult ? (
					<div style={{ textAlign: "center" }}>
						<img src="/assets/swal-success.png" width="80" />
						<a
							style={{ position: "absolute", right: 20 }}
							onClick={() =>
								(window.location.href =
									"https://myaccident.org/workers-compensation")
							}
						>
							<img src="/assets/icon-close.png" />
						</a>
						<br />
						<br />
						<h2 style={{ textAlign: "center" }}>
							{t("YOU_RE_ALL_SET", { name: userData.name?.split(" ")[0] })}
						</h2>
						<br />
						<div className="row">
							<div style={{ textAlign: "center" }}>
								{t("OUR_TEAM_WILL_REACH_OUT")}
							</div>
						</div>
						<br />
						<div style={{ height: 1, background: "#CCC" }}></div>
						<br />
						<p style={{ textAlign: "center", color: "var(--orange)" }}>
							<Trans
								i18nKey={
									"YOU_REQUESTED_TO_SPEAK_TO_SOMEONE_ABOUT_YOUR_ACCIDENT"
								}
							/>{" "}
						</p>
						<br />
						{preferredTimes.map((v) => (
							<div
								style={{
									maxWidth: "170px",
									margin: "10px auto",
									cursor: "pointer",
									display: "flex"
								}}
								onClick={() => setPreferredTime(v)}
								key={v}
							>
								<span style={{ marginRight: 8 }}>
									<Checkbox checked={preferredTime === v} />
								</span>
								{v}
							</div>
						))}
						<br />
						<p style={{ textAlign: "center", fontSize: 12 }}>
							{t("MYACCIDENT_ORG_OPERATES_BETWEEN_9_AM_AND_5_PM")}
						</p>
						<br />
						<div style={{ height: 1, background: "#CCC" }}></div>
						<br />
						<div style={{ textAlign: "center" }}>
							<button
								style={{ margin: "auto", minWidth: 250 }}
								className="green"
								disabled={!preferredTime}
								onClick={() => updatePreferredTime()}
							>
								{t("SUBMIT")}
							</button>
							{/* <br />
							<a
								style={{
									fontSize: 12,
									textDecoration: "underline",
									color: "#666"
								}}
								onClick={() => showCancelModal()}
							>
								Cancel Request
							</a>
							<br />
							<br /> */}
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
}
