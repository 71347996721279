import { useState } from "react";
import Axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export default function UpdateAccidentReasonForm({ ids, lead }) {
	const history = useHistory();
	const { t } = useTranslation();
	const [accidentReason, setAccidentReason] = useState("");

	const updateAccidentReason = async (event) => {
		event.preventDefault();

		const body = {
			...lead,
			extra_details: {
				...lead?.extra_details,
				accidentDetails: {
					...lead?.extra_details?.accidentDetails,
					accidentReason
				}
			}
		};
		if (accidentReason?.length && ids?.length) {
			ids.forEach(async (lead_id) => {
				body.lead_id = lead_id;
				try {
					await Axios.put("leads/update", body);
				} catch (error) {
					console.log(error);
				}
			});
		}

		handleClose();
	};

	const handleClose = () => {
		Swal.fire(t("THANK_YOU"), t("REQUEST_RECEIVED"), "success").then(() => {
			history.push("/");
		});
	};

	return (
		<form className="form-center" onSubmit={(e) => updateAccidentReason(e)}>
			<h4 className="form-subtitle">{t("TELL_US_WHAT_HAPPENED")}</h4>
			<div className="input-container">
				<label htmlFor="accidentReason">{t("ACCIDENT_DESCRIPTION")}</label>
				<textarea
					id="accidentReason"
					value={accidentReason}
					onChange={(e) => setAccidentReason(e.target.value)}
				/>
			</div>

			<div className="group-buttons">
				<button type="button" className="button-disabled" onClick={handleClose}>
					{t("CLOSE")}
				</button>
				<button type="submit" className="green">
					{t("SUBMIT")}
				</button>
			</div>
		</form>
	);
}
