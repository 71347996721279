/* istanbul ignore file */
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
let mapInstance;
let marker;
let circle;

const mapCenters = {
	ca: { lat: 36.778261, lng: -119.4179324 },
	tx: { lat: 31.968599, lng: -99.90181 },
	ma: { lat: 42.407211, lng: -71.382439 },
	fl: { lat: 27.664827, lng: -81.515755 },
	oh: { lat: 40.417286, lng: -82.90712 },
	il: { lat: 40.633125, lng: -89.398529 },
	ky: { lat: 37.839333, lng: -84.27002 },
	ut: { lat: 39.32098, lng: -111.093735 },
	ct: { lat: 41.603222, lng: -73.087746 },
	ia: { lat: 41.9328382, lng: -94.5134894 },
	ny: { lat: 40.712776, lng: -74.005974 },
	default: { lat: 35.169621, lng: -99.683617 }
};

export default function MapPicker({
	setLocation,
	setAddress,
	location,
	height,
	zoom,
	radius,
	onPlaceSelected
}) {
	const loc = useLocation();
	const rad = useRef(radius);

	const placeMarker = (location) => {
		if (marker) {
			marker.setMap(null);
			circle.setMap(null);
		}

		let m = new window.google.maps.Marker({
			position: location,
			map: mapInstance,
			draggable: true
		});

		let c = new window.google.maps.Circle({
			map: mapInstance,
			radius: rad.current || 16093,
			fillColor: "#AA0000"
		});
		c.bindTo("center", m, "position");

		marker = m;
		circle = c;

		mapInstance.setCenter(m.position);
		mapInstance.setZoom(9);
		setLocation(location);

		const geocoder = new window.google.maps.Geocoder();

		geocoder.geocode({ location }, (results, status) => {
			if (status === "OK") {
				if (results[0]) {
					setAddress(results[0].formatted_address);
					if (onPlaceSelected) onPlaceSelected(results[0]);
				} else {
					alert("No results found");
				}
			} else {
				alert("Geocoder failed due to: " + status);
			}
		});
	};

	useEffect(() => {
		const startAt = loc.pathname.slice(1);

		setTimeout(() => {
			mapInstance = new window.google.maps.Map(document.getElementById("map"), {
				center: mapCenters[startAt] ? mapCenters[startAt] : mapCenters.default,
				zoom: startAt === "ny" ? 10 : zoom || 4,
				options: {
					streetViewControl: false,
					mapTypeControl: false,
					fullscreenControl: false,
					draggableCursor: "default"
				},
				gestureHandling: "greedy"
			});
			window.google.maps.event.addListener(
				mapInstance,
				"click",
				function (event) {
					placeMarker(event.latLng);
				}
			);
			if (location) placeMarker(location);
		}, 300);
	}, []);

	useEffect(() => {
		rad.current = radius;
		if (mapInstance && marker) {
			marker.setMap(null);
			circle.setMap(null);
		}
	}, [radius]);

	return <div id="map" style={{ height }} />;
}
